import { gql } from '@apollo/client';

export const GROUPS_QUERY = gql`
  query Groups($stageId: ID!) {
    groups(stageId: $stageId, orderBy: "created_at") {
      id
      key
      name
      progress
      firstTask {
        id
      }
    }
  }
`;
