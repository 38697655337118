import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import MdIcon from '../../MdIcon';
import Status from '../../Status';
import SageLink from '../../SageLink';
import { STAGE_QUERY } from '../queries';
import GroupsWithData from '../../Groups';
import ProgressBar from '../../Progress/ProgressBar';
import TasksListWithData from '../../Tasks/TasksList';
import { StageApprovalAlert, StageNoTasksAlert } from '../StageCompleteAlert';
import STATUS_MAP, { STATUS_VALUES } from '../../../constants/statuses';
import useCopyToClipboard from '../../../utils/copyToClipboard';

export const StagePage = ({ stage }) => {
  const { id, name, status, pipeline, progress, tasksCount } = stage;
  const copyToClipboard = useCopyToClipboard();

  return (
    <Container maxWidth="xl">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        data-testid={`stage-${id}`}
      >
        <Typography component="h1" variant="h4" noWrap flex="0 0 1000px">
          {name}
        </Typography>
        <Box>
          <Status data-testid="stage-status" value={status} variant="medium" />
          <IconButton onClick={() => copyToClipboard(id)}>
            <MdIcon mh={1} name="copy" />
          </IconButton>
        </Box>
      </Box>
      <Breadcrumbs aria-label="breadcrumb">
        <SageLink to="/">Pipelines</SageLink>
        <Typography>{pipeline.pipelineType.name}</Typography>
        <SageLink data-testid="pipeline-page-link" to={`/pipelines/${pipeline.id}`}>
          {pipeline.name}
        </SageLink>
        <Typography color="textPrimary">{name}</Typography>
      </Breadcrumbs>
      <ProgressBar
        data-testid="stage-page-progress"
        label="Stage Progress"
        completed={progress.completed}
        total={progress.total}
        component={Box}
        mb={4}
      />
      {status === STATUS_MAP.AWAITING_CONFIRMATION.value && (
        <StageApprovalAlert stageId={id} mb={2} />
      )}
      {status === STATUS_MAP.STARTED.value && tasksCount === 0 && (
        <StageNoTasksAlert stageId={id} mb={2} />
      )}
      <GroupsWithData stageId={id} mb={4} />
      <TasksListWithData stageId={id} />
    </Container>
  );
};

StagePage.propTypes = {
  stage: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    status: PropTypes.oneOf(STATUS_VALUES).isRequired,
    pipeline: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }),
};

const StagePageWithData = () => {
  const { stageId } = useParams();

  const { loading, error, data } = useQuery(STAGE_QUERY, {
    variables: { id: stageId },
  });

  if (loading) return <LinearProgress />;
  if (error)
    return (
      <Alert severity="error">
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    );

  return !data.stage ? (
    <Alert severity="info">No Stage with ID {stageId}</Alert>
  ) : (
    <StagePage stageId={stageId} stage={data.stage} />
  );
};

export default StagePageWithData;
