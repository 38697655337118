import React, { useRef, useMemo, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import LinkIcon from '@material-ui/icons/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import InsertIndicator from './InsertIndicator';
import { getColumnStyle, dragDisabledStyle } from './utils';

const useStyles = makeStyles({
  innerBox: {
    padding: '0.8rem 0 0.6rem',
    width: 'fit-content',
    whiteSpace: 'nowrap',
    borderTop: '0.05rem solid #e1e1e1',
    borderBottom: '0.05rem solid #e1e1e1',
  },
  dragIcon: {
    cursor: 'grab',
    color: '#6a6363',
    fontSize: '1.2rem',
    marginTop: '0.13rem',
  },
  linkIcon: {
    cursor: 'pointer',
    color: '#007BFF',
    fontSize: '1.2rem',
    marginTop: '0.13rem',
  },
});

const TableRow = ({ id, index, record, canDrag, dropTarget, columnsWidth }) => {
  const ref = useRef(null);
  const classes = useStyles();
  const [isDragging, setIsDragging] = useState(false);
  const [styles, setStyles] = useState({ transition: null, backgroundColor: '#ffffff' });

  const isActiveDropTarget = useMemo(() => {
    return !!dropTarget && id === dropTarget.id && id !== dropTarget.srcRowId;
  }, [id, dropTarget]);

  useEffect(() => {
    if (ref.current) {
      return combine(
        dropTargetForElements({
          element: ref.current,
          getData: () => ({ rowId: id, rowIndex: index }),
        }),
        draggable({
          element: ref.current,
          canDrag: () => !!canDrag,
          onDrop: () => {
            setIsDragging(false);
            setStyles({ transition: null, backgroundColor: '#eaf5ff' });

            setTimeout(() => {
              setStyles({
                transition: 'background-color 0.5s linear',
                backgroundColor: '#ffffff',
              });
            }, 1000);
          },
          onDragStart: () => setIsDragging(true),
          getInitialData: () => ({ rowId: id, rowIndex: index }),
        })
      );
    }
  }, [id, index, canDrag]);

  return (
    <Box
      ref={ref}
      role="table-row"
      style={{ ...styles, ...(isDragging ? { opacity: 0.5 } : {}) }}
      onTransitionEnd={() => setStyles({ transition: null, backgroundColor: '#ffffff' })}
    >
      {isActiveDropTarget && dropTarget.insertPosition === 'top' && <InsertIndicator />}

      <Box className={classes.innerBox}>
        <Box style={getColumnStyle(columnsWidth.drag, 'center')}>
          <DragIndicatorIcon
            className={classes.dragIcon}
            style={canDrag ? undefined : dragDisabledStyle}
          />
        </Box>
        <Box style={getColumnStyle(columnsWidth.link, 'center')}>
          <a href={record.presigned_url} target="_blank" rel="noopener noreferrer">
            <LinkIcon className={classes.linkIcon} />
          </a>
        </Box>
        <Box style={getColumnStyle(columnsWidth.title)}>
          <Typography variant="subtitle2">{record.title}</Typography>
        </Box>
        <Box style={getColumnStyle(columnsWidth.date)}>
          <Typography variant="subtitle2">{record.published_date}</Typography>
        </Box>
      </Box>

      {isActiveDropTarget && dropTarget.insertPosition === 'bottom' && <InsertIndicator />}
    </Box>
  );
};

export default TableRow;
