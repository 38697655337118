import React from 'react';

import styles from './index.module.scss';

export default function ActionButton({ className = '', iconClassName = '', ...restProps }) {
  return (
    <div className={`${styles.ActionButton} ${className}`} {...restProps}>
      <i className={iconClassName} />
    </div>
  );
}
