import React, { useState } from 'react';
import { HashRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { AuthProvider, useAuth } from '../Auth';
import { Header, Sidebar, Main } from '../Layout';
import LoginPage from '../LoginPage';
import UpdateBoundary from '../UpdateBoundary';

import { ApiClientProvider, makeApiClient } from '../../clients/api';
import { makeApolloClient } from '../../clients/apollo';

import * as stores from '../../stores';
import theme from './theme';
import '../Legacy/App/index.scss';

export const App = () => {
  const { isLoggedIn } = useAuth();
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  if (!isLoggedIn) return <LoginPage />;

  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <CssBaseline />
        <Header isDrawerOpen={isDrawerOpen} setDrawerOpen={setDrawerOpen} />
        <Sidebar isDrawerOpen={isDrawerOpen} setDrawerOpen={setDrawerOpen} />
        <Main />
      </HashRouter>
    </ThemeProvider>
  );
};

export const WrappedApp = () => {
  const apolloClient = makeApolloClient();
  const apiClient = makeApiClient();

  return (
    <ApiClientProvider client={apiClient}>
      <UpdateBoundary defaultGitSHA={window.ENV.GIT_SHA}>
        <ApolloProvider client={apolloClient}>
          <AuthProvider>
            <App {...stores} />
          </AuthProvider>
        </ApolloProvider>
      </UpdateBoundary>
    </ApiClientProvider>
  );
};

export default WrappedApp;
