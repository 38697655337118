import { useState, useEffect } from 'react';

export const dragDisabledStyle = {
  color: '#9d9d9d8a',
  cursor: 'not-allowed',
};

export const getColumnStyle = (width, textAlign = 'left') => ({
  width,
  textAlign,
  padding: '0 1rem',
  overflow: 'hidden',
  whiteSpace: 'normal',
  verticalAlign: 'top',
  display: 'inline-block',
});

export const checkRegulatorName = value =>
  typeof value === 'string' && !!value.trim() && /^[a-z0-9\s_-]+$/i.test(value);

export const orderByPublishedDate = records =>
  records.slice().sort((a, b) => new Date(a.published_date) - new Date(b.published_date));

export const useColumnsWidth = (parentRef, columnsConfig = {}, minColumnWidth = 150) => {
  const [columnsWidth, setColumnsWidth] = useState(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      if (entries.length > 0) {
        let totalColumnWidth = 0;
        let expandableColumnCount = 0;
        const resolvedColumnsConfig = {};
        const parentWidth = entries[0].contentRect.width;

        for (const [column, options] of Object.entries(columnsConfig)) {
          const fixed = options.fixed === true;

          const width =
            options.width === undefined || !Number.isInteger(options.width) || options.width <= 0
              ? minColumnWidth
              : options.width;

          totalColumnWidth += width;

          if (!fixed) expandableColumnCount += 1;

          resolvedColumnsConfig[column] = { width, fixed };
        }

        const availableParentWidth = parentWidth - totalColumnWidth;

        const sharedColumnWidth =
          expandableColumnCount > 0 && availableParentWidth > 0
            ? availableParentWidth / expandableColumnCount
            : 0;

        const newColumnsWidth = Object.entries(resolvedColumnsConfig).reduce(
          (result, [column, { width, fixed }]) => ({
            ...result,
            [column]: `${width + (fixed ? 0 : sharedColumnWidth)}px`,
          }),
          {}
        );

        setColumnsWidth(newColumnsWidth);
      }
    });

    if (parentRef.current) {
      resizeObserver.observe(parentRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [columnsConfig, minColumnWidth, parentRef]);

  return columnsWidth;
};
