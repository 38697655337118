import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';

const MdIcon = ({ name, outlined = true, className, ...restProps }) => {
  return (
    <Icon
      {...restProps}
      className={`${className || ''} ${outlined ? 'material-icons-outlined' : ''}`}
    >
      {name}
    </Icon>
  );
};

MdIcon.muiName = Icon.muiName;

MdIcon.propTypes = {
  name: PropTypes.string,
  outlined: PropTypes.bool,
  className: PropTypes.string,
};

export default MdIcon;
