import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';

import { PUBLICATION_STATUSES } from '../../../constants/statuses';
import { PIPELINES_BY_TYPE_QUERY, PIPELINE_TYPES_QUERY, START_PIPELINE_MUTATION } from '../queries';

const PipelineStartPage = ({
  error,
  loading,
  infoText,
  pipelineName,
  pipelineType,
  pipelineTypes,
  onSubmit,
  onFieldChange,
  onSelectChange,
}) => (
  <Container>
    <Box mb={6}>
      <Typography component="h1" variant="h4" noWrap>
        Start Pipeline
      </Typography>
      <Box my={2} component={Alert} severity={error ? 'error' : 'info'}>
        {error ? error : infoText}
      </Box>
      {loading && <LinearProgress />}
    </Box>
    <Box display="flex" component="form" onSubmit={onSubmit}>
      <Box minWidth={350}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="pipeline-types-select-label-id">Pipeline Types</InputLabel>
          <Select
            label="Pipeline Types"
            labelId="pipeline-types-select-label-id"
            data-testid="pipeline-types-select"
            value={pipelineType?.id || ''}
            onChange={onSelectChange}
          >
            {pipelineTypes.map(pt => (
              <MenuItem key={pt.id} value={pt.id}>
                {pt.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box mx={3} minWidth={350}>
        <TextField
          fullWidth
          variant="outlined"
          label="Pipeline Name"
          aria-label="Pipeline Name"
          data-testid="pipeline-name-field"
          value={pipelineName}
          onChange={onFieldChange}
        />
      </Box>
      <Button color="primary" variant="contained" data-testid="pipeline-start-button" type="submit">
        Start
      </Button>
    </Box>
  </Container>
);

PipelineStartPage.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  infoText: PropTypes.string,
  pipelineName: PropTypes.string,
  pipelineType: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.oneOf(Object.values(PUBLICATION_STATUSES)),
  }),
  pipelineTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      status: PropTypes.oneOf(Object.values(PUBLICATION_STATUSES)),
    })
  ),
  onSubmit: PropTypes.func,
  onFieldChange: PropTypes.func,
  onSelectChange: PropTypes.func,
};

const PipelineStartPageWithData = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [pipelineName, setPipelineName] = useState('');
  const [pipelineTypeId, setPipelineTypeId] = useState(null);
  const { status = PUBLICATION_STATUSES.PUBLISHED } = useParams();

  useEffect(() => {
    setPipelineTypeId(null);
  }, [status]);

  const pipelineTypesResponse = useQuery(PIPELINE_TYPES_QUERY, { variables: { status } });

  const [startPipeline, startPipelineResponse] = useMutation(START_PIPELINE_MUTATION, {
    variables: { pipelineName, pipelineTypeId },
    refetchQueries: [
      {
        query: PIPELINES_BY_TYPE_QUERY,
        variables: {
          pipelinesStatuses: ['started', 'errored'],
        },
      },
    ],
  });

  const pageError = error || pipelineTypesResponse.error?.message;
  const pageLoading = pipelineTypesResponse.loading || startPipelineResponse.loading;

  const pipelineTypes =
    pipelineTypesResponse.error || pipelineTypesResponse.loading
      ? []
      : pipelineTypesResponse.data.pipelineTypes;

  const pipelineType = pipelineTypes.find(pt => pt.id === pipelineTypeId);

  const infoText =
    'Provide a value for all fields and click start to create and start the pipeline';

  const onSelectChange = event => {
    if (error) setError(null);
    setPipelineTypeId(event.target.value);
  };

  const onFieldChange = event => {
    if (error) setError(null);
    setPipelineName(event.target.value);
  };

  const onSubmit = async event => {
    event.preventDefault();

    if (error) setError(null);

    if (!pipelineTypeId) return setError('No valid pipeline type has been selected');
    else if (!pipelineName.trim()) return setError('The pipeline name provided is not valid');

    try {
      const {
        data: {
          startPipeline: { pipelineId },
        },
      } = await startPipeline();

      history.push(`/pipelines/${pipelineId}`);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <PipelineStartPage
      error={pageError}
      loading={pageLoading}
      infoText={infoText}
      pipelineName={pipelineName}
      pipelineType={pipelineType}
      pipelineTypes={pipelineTypes}
      onSubmit={onSubmit}
      onFieldChange={onFieldChange}
      onSelectChange={onSelectChange}
    />
  );
};

export default PipelineStartPageWithData;
