import React, { Component } from 'react';

class CommentsBox extends Component {
  constructor(props) {
    super(props);
    this.openedCommentContainer = React.createRef();
    document.addEventListener('click', this.onDocumentClicked);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onDocumentClicked);
  }

  onDocumentClicked = e => {
    let { current } = this.openedCommentContainer;
    if (
      current &&
      !this.isDescendent(e.target, current) &&
      !this.isRelatedActionButton(e.target, current)
    ) {
      this.props.onClose();
    }
  };

  isActionButton = (target, current) =>
    target.className.includes('ActionButton_') ||
    target.parentNode?.className.includes('ActionButton_');

  isRelatedActionButton = (target, current) =>
    this.isActionButton(target) &&
    (current.previousSibling === target || current.previousSibling === target.parentNode);

  isDescendent = (child, parent) => {
    var node = child.parentNode;
    while (node != null) {
      if (node === parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  };

  render() {
    let {
      comments,
      isVisible,
      newComments,
      onClose,
      onSubmit,
      isDisabled = false,
      ...restProps
    } = this.props;
    return !isVisible ? null : (
      <div {...restProps} ref={this.openedCommentContainer}>
        <div style={{ color: !comments && 'gray' }} className="padding-right-1">
          {(comments || 'No comments found.').split('\n\n').map((c, i) => (
            <p key={i}>{c}</p>
          ))}
        </div>
        <textarea
          placeholder="Add a comment..."
          style={{ width: '100%' }}
          defaultValue={newComments}
          disabled={isDisabled}
          onChange={onSubmit}
        />
        <button
          onClick={onClose}
          className="btn button small float-right"
          disabled={isDisabled}
          type="submit"
        >
          Save &amp; Close
        </button>
      </div>
    );
  }
}

export default CommentsBox;
