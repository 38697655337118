import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import ActionButton from '../ActionButton';

const InputGroup = styled.div`
  ${tw`relative w-full flex`}
`;

const InputGroupPrepend = styled.div`
${tw`w-full inline-flex`}
  margin-right: -1px;
  &::after{
    content: '${props => props.content}';
    ${tw`text-dark-gray absolute m-auto text-xs`}
    font-style: italic;
    left: 1em;
    top: 20%;
  }
`;

const SageInput = styled.input`
  display: flex;
  box-shadow: inset 0 1px 2px rgba(22, 61, 101, 0);
  transition: all 0.2s ease-in-out;
  padding-left: 100px;
  &:focus {
    box-shadow: 0 0 5px rgba(22, 61, 101, 0.66);
  }
  &::placeholder {
    font-style: italic;
  }
`;

const Wrapper = styled.div`
  ${tw`flex w-full my-1`}
`;
const ButtonWrapper = styled.div`
  ${tw`ml-6 my-4 w-1/2 float-right m-auto`}
`;

const NameWrapper = styled.div`
  ${tw`my-1 w-full`}
`;

const GroupEditor = ({ name, onChange, onKeyDown, handleDeleteClick }) => (
  <Wrapper>
    <NameWrapper>
      <InputGroup>
        <InputGroupPrepend content="Group Name">
          <SageInput
            type="text"
            value={name}
            placeholder="Group Name..."
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
        </InputGroupPrepend>
      </InputGroup>
    </NameWrapper>
    <ButtonWrapper>
      <label style={tw`text-right`}>
        <span style={tw`w-auto inline-flex h-auto mx-2 `}>Delete Group</span>
        <ActionButton
          style={tw`relative`}
          iconClassName="fas fa-times"
          onClick={handleDeleteClick}
        />
      </label>
    </ButtonWrapper>
  </Wrapper>
);

export default GroupEditor;
