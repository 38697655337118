import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles(theme => ({
  taskInfo: {
    display: 'flex',
    flexGrow: theme.spacing(2),
    margin: theme.spacing(0, 6),
    padding: theme.spacing(1.8, 0),
  },
  prevBut: {
    borderRadius: 0,
    borderRight: '0.1rem solid #D6D6D6',
  },
  nextBut: {
    borderRadius: 0,
    borderLeft: '0.1rem solid #D6D6D6',
  },
}));

const TaskNavbar = ({ task, ...rest }) => {
  const classes = useStyles();
  const { prevId, nextId, name, description } = task;

  return (
    <Box {...rest} display="flex" component={Paper}>
      <Button
        component={Link}
        disabled={!prevId}
        to={`/tasks/${prevId}`}
        data-testid="test-navbut"
        className={classes.prevBut}
      >
        <KeyboardArrowLeft />
      </Button>
      <Box className={classes.taskInfo}>
        <Grid container>
          <Grid item xs={3}>
            <Typography>{name}</Typography>
          </Grid>
          <Grid item xs>
            <Typography>{description}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Button
        component={Link}
        disabled={!nextId}
        to={`/tasks/${nextId}`}
        data-testid="test-navbut"
        className={classes.nextBut}
      >
        <KeyboardArrowRight />
      </Button>
    </Box>
  );
};

TaskNavbar.propTypes = {
  task: PropTypes.shape({
    name: PropTypes.string,
    order: PropTypes.number,
    prevId: PropTypes.string,
    nextId: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default TaskNavbar;
