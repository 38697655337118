import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { computed, decorate } from 'mobx';
import { observer } from 'mobx-react';
import CommentsBox from '../../Legacy/CommentsBox';
import { Submit } from '../../Legacy/Forms';
import ActionButton from '../../Legacy/ActionButton';
import Alert from '../../Legacy/Alert';
import Loading from '../../Legacy/Loading';
import styles from './index.module.scss';

class RulesTask extends Component {
  state = {
    showFullText: false,
    ruleInputs: {},
  };

  isSubjectBased = false;

  constructor(props) {
    super(props);
    this.state.ruleInputs = this.props.savedProgress || {};
    this.fullTextScrollable = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.savedProgress !== this.props.savedProgress) {
      this.setState({ ruleInputs: this.props.savedProgress || {} });
    }
  }

  componentWillUnmount() {
    this.toggleFullText(false);
  }

  // Props
  // ---------------------------------------------------------------------------
  get isComplete() {
    return this.task.status === 'completed';
  }

  get isLoading() {
    return this.props.isLoadingTask;
  }

  get isCompletingTask() {
    return this.props.isCompletingTask;
  }

  get task() {
    return this.props.task;
  }

  get inputs() {
    return this.props.inputs;
  }

  get taskInputValue() {
    return this.inputs.find(i =>
      i.result_type.slug === this.isSubjectBased ? 'SubjectRules' : 'IndividualRule'
    ).value;
  }

  get regulatorRules() {
    const rulesInput = this.inputs.find(i => i.result_type.slug === 'CsvData');

    if (!rulesInput) return [];

    const [headers, ...rows] = rulesInput.value;

    const tempIdColumnIdx = headers.indexOf('temp_id');
    const baseContentColumnIdx = headers.indexOf('base_content');

    return rows.map(r => ({
      temp_id: r[tempIdColumnIdx],
      base_content: r[baseContentColumnIdx],
    }));
  }

  get identifiersInput() {
    return this.inputs.find(i => i.result_type.slug === 'Identifiers').value;
  }

  get regulatorsOptions() {
    return this.identifiersInput.regulators.map(r => ({
      value: r.name,
      data: r,
      label: r.name,
    }));
  }

  get modulesOptions() {
    return this.identifiersInput.modules.map(m => ({
      value: m.name,
      data: m,
      label: m.name,
    }));
  }

  get subjectsOptions() {
    return this.identifiersInput.subjects.map(s => ({
      value: s.name,
      data: s,
      label: s.name,
    }));
  }

  get parentRulesOptions() {
    return this.identifiersInput.parent_rules.map(pr => ({
      value: pr.name,
      data: pr,
      label: pr.name,
    }));
  }

  get rulesOptions() {
    return this.identifiersInput.rules.map(r => ({
      value: r.temp_id,
      data: r,
      label: r.name || r.parent_name,
    }));
  }

  get rawRules() {
    if (this.isLoading) return [];
    return this.isSubjectBased ? this.taskInputValue.rules : [this.taskInputValue];
  }

  get mergedRules() {
    return this.rawRules.map(rawRule => {
      const inputs = this.state.ruleInputs[rawRule.temp_id] || {};
      return {
        ...this.mergeRule(rawRule, inputs),
        splits: (inputs.splits || []).map(r =>
          this.mergeRule(r, this.state.ruleInputs[r.temp_id] || {})
        ),
      };
    });
  }

  get mergedRulesMap() {
    return this.mergedRules.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.temp_id]: cur,
      }),
      {}
    );
  }

  // PLACEHOLDER
  mergeRule(rawRule, inputs) {
    return {
      ...rawRule,
      ...inputs,
    };
  }

  // Rule Actions
  // ---------------------------------------------------------------------------

  setRuleValues(rule, newValues, cb) {
    this.setRuleInputs(
      {
        [rule.temp_id]: {
          ...this.state.ruleInputs[rule.temp_id],
          ...newValues,
        },
      },
      cb
    );
  }

  setAllRulesValues(newValues, cb) {
    const ruleInputs = this.mergedRules.reduce(
      (acc, rule) => ({
        ...acc,
        [rule.temp_id]: {
          ...acc[rule.temp_id],
          ...newValues,
        },
      }),
      { ...this.state.ruleInputs }
    );

    this.setRuleInputs(ruleInputs, cb);
  }

  setRuleInputs(newRuleInputs, cb) {
    const ruleInputs = { ...this.state.ruleInputs, ...newRuleInputs };
    this.props.saveProgress(ruleInputs);
    this.setState({ ruleInputs }, cb);
  }

  // Submission
  // ---------------------------------------------------------------------------

  get isValid() {
    return this.errors.length === 0;
  }

  // PLACEHOLDER
  get output() {
    return {
      rules: this.mergedRules,
    };
  }

  getOutputComments(comments, newComments) {
    return [comments, newComments].filter(c => !!c).join('\n\n-----\n\n');
  }

  // PLACEHOLDER
  get errors() {
    return [];
  }

  submit() {
    this.props.completeTask(this.output, this.state.ruleInputs).catch(console.log);
  }

  // View Actions
  // ---------------------------------------------------------------------------

  toggleCommentsBox(rule) {
    return this.isSubjectBased
      ? this.setRuleValues(rule, { isCommentsVisible: !rule.isCommentsVisible })
      : this.setRuleInputs({
          isCommentsVisible: !this.state.ruleInputs.isCommentsVisible,
        });
  }

  toggleFullText(val, cb) {
    const showFullText = val !== undefined ? val : !this.state.showFullText;
    this.setState({ showFullText }, cb);
    document.body.classList.toggle('right-rail-open', showFullText);
  }

  scrollToText(rule) {
    if (!this.state.showFullText || this.isLoadingRegulatorContent) return;

    const el = this.fullTextScrollable.current.querySelector(`[temp-id="${rule.temp_id}"]`);

    if (el) this.fullTextScrollable.current.scrollTo(0, el.offsetTop);
  }

  jumpToRule(rule) {
    this.toggleFullText(true, () => this.scrollToText(rule));
  }

  // Render Methods
  // ---------------------------------------------------------------------------

  renderMain() {
    return (
      <div className={styles.main}>
        <div className="callout secondary">
          <div className="grid-x grid-margin-x">
            <div className="cell medium-8">
              <div className="grid-x">
                <div className="cell medium-3 font-bold">Regulator:</div>
                <div className="cell medium-9">{this.taskInputValue.regulator_name}</div>
                <div className="cell medium-3 font-bold">Module:</div>
                <div className="cell medium-9">{this.taskInputValue.ascent_module_name}</div>
                <div className="cell medium-3 font-bold">Subject:</div>
                <div className="cell medium-9">{this.taskInputValue.subject_name}</div>
                {!this.isSubjectBased && (
                  <>
                    <div className="cell medium-3 font-bold">Rule:</div>
                    <div className="cell medium-9">
                      {this.taskInputValue.child_rule_number ||
                        this.taskInputValue.parent_rule_number}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="cell medium-4">
              <div className="grid-x">
                <div className="cell medium-6 font-bold">Effective:</div>
                <div className="cell medium-6">{this.taskInputValue.document_start_date}</div>
                <div className="cell medium-6 font-bold">Published:</div>
                <div className="cell medium-6">{this.taskInputValue.document_published_date}</div>
              </div>
            </div>
          </div>
          {this.renderActions()}
        </div>
        {this.renderRules()}
      </div>
    );
  }

  renderRuleComments(rule, newComments, isCommentsVisible) {
    return (
      <div style={{ position: 'relative' }}>
        <ActionButton
          className="margin-bottom-half"
          style={{
            background: (!!rule.comments || !!newComments) && '#fff67a',
            zIndex: isCommentsVisible ? '999' : '0',
            position: 'relative',
          }}
          title="View/Add Comments"
          iconClassName="far fa-comment"
          onClick={() => this.toggleCommentsBox(rule)}
        />
        <CommentsBox
          className="shadow"
          style={{
            position: 'absolute',
            top: '-5px',
            right: '-5px',
            minWidth: '600px',
            background: 'white',
            border: '1px solid white',
            padding: '1em 1em 0',
            zIndex: '100',
            borderRadius: '15px',
          }}
          comments={rule.comments}
          isVisible={isCommentsVisible}
          isDisabled={this.isComplete}
          onSubmit={e =>
            this.isSubjectBased
              ? this.setRuleValues(rule, { newComments: e.target.value })
              : this.setRuleInputs({
                  newComments: e.target.value,
                })
          }
          onClose={() => this.toggleCommentsBox(rule)}
          newComments={newComments}
        />
      </div>
    );
  }

  // PLACEHOLDER
  renderRules(rule) {
    return <div />;
  }

  renderActions() {
    return null;
  }

  renderLoading() {
    return <Loading className="margin-auto margin-top-2" type="large" isLoading={true} />;
  }

  renderLinesAsParagraphs(content) {
    return (content || '').split('\n\n').map((chunk, idx) => <p key={idx}>{chunk}</p>);
  }

  renderFullText() {
    if (this.isLoadingRegulatorContent)
      return <div className={styles.fullText}>{this.renderLoading()}</div>;

    const ruleIDs = this.mergedRules.reduce(
      (acc, curr, idx) => ({
        ...acc,
        [curr.temp_id]: true,
      }),
      {}
    );

    const wrapperClass = this.state.showFullText ? styles.fullTextShown : styles.fullText;

    return (
      <div className={wrapperClass}>
        <div className={styles.fullTextScrollable} ref={this.fullTextScrollable}>
          {this.regulatorRules.map(u => (
            <p
              temp-id={u.temp_id}
              key={u.temp_id}
              className={ruleIDs[u.temp_id] ? styles.currentRule : ''}
            >
              {u.base_content}
            </p>
          ))}
        </div>
        <ActionButton
          className={styles.fullTextToggle}
          iconClassName={this.state.showFullText ? 'fas fa-chevron-right' : 'far fa-file-alt'}
          onClick={() => this.toggleFullText()}
        />
      </div>
    );
  }

  renderSubmit() {
    return (
      <div>
        <hr />
        <Submit
          buttonRef={this.props.submitRef}
          className="margin-right-2"
          disabled={this.isCompletingTask || !this.isValid || this.isComplete}
          isLoading={this.isCompletingTask}
          onClick={() => !this.isCompletingTask && this.submit()}
        />
        {this.errors.map((err, i) => (
          <Alert key={i} type="error" message={err} />
        ))}
        {this.props.showOutput && (
          <div>
            <strong>Inputs</strong>
            <pre>{JSON.stringify(this.state.ruleInputs, null, 2)}</pre>
            <strong>Output</strong>
            <pre>{JSON.stringify(this.output, null, 2)}</pre>
          </div>
        )}
      </div>
    );
  }

  render() {
    if (this.isLoading || !this.inputs.length) return this.renderLoading();

    return (
      <div className="grid-x">
        <div className="cell medium-12">{this.renderMain()}</div>
        <div className="cell medium-12 padding-top-2">{this.renderSubmit()}</div>
        {this.renderFullText()}
      </div>
    );
  }
}

export default observer(
  decorate(RulesTask, {
    errors: computed,
    identifiersInput: computed,
    inputs: computed,
    isComplete: computed,
    isCompletingTask: computed,
    isLoading: computed,
    isValid: computed,
    mergedRules: computed,
    mergedRulesMap: computed,
    modulesOptions: computed,
    output: computed,
    parentRulesOptions: computed,
    rawRules: computed,
    regulatorRules: computed,
    regulatorsOptions: computed,
    rulesOptions: computed,
    subjectsOptions: computed,
    task: computed,
    taskInputValue: computed,
  })
);

RulesTask.propTypes = {
  completeTask: PropTypes.func.isRequired,
  saveProgress: PropTypes.func.isRequired,
  savedProgress: PropTypes.object,
};
