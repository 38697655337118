import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { getColumnStyle } from './utils';

const useStyles = makeStyles({
  root: {
    color: '#595959',
    width: 'fit-content',
    whiteSpace: 'nowrap',
    background: '#f4f4f4',
    padding: '0.8rem 0 0.6rem',
    borderTop: '0.05rem solid #e1e1e1',
    borderBottom: '0.05rem solid #e1e1e1',
  },
  text: {
    fontWeight: '600',
    fontSize: '0.8rem',
  },
});

const TableHeader = ({ columnsWidth }) => {
  const classes = useStyles();

  return (
    <Box role="table-header" className={classes.root}>
      <Box style={getColumnStyle(columnsWidth.drag)} />
      <Box style={getColumnStyle(columnsWidth.link)} />

      <Box style={getColumnStyle(columnsWidth.title)}>
        <Typography variant="subtitle2" className={classes.text}>
          Title
        </Typography>
      </Box>

      <Box style={getColumnStyle(columnsWidth.date)}>
        <Typography variant="subtitle2" className={classes.text}>
          Published Date
        </Typography>
      </Box>
    </Box>
  );
};

export default TableHeader;
