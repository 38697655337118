// AIC Groups
const REQUIREMENT = { value: 'R', shortLabel: 'Req', label: 'Requirement' };
const SUPPORTING_INFO = { value: 'SI', shortLabel: 'SI', label: 'Supporting Information' };
const DEFINITION = { value: 'D', shortLabel: 'Def', label: 'Definition' };
const G = { value: 'G', shortLabel: 'G', label: 'Government' };
const Z = { value: 'Z', shortLabel: 'Z', label: 'Other' };

// AIC Specifics
const RA = { value: 'RA', shortLabel: 'RA', label: 'Requirement Actionable' };
const RM = { value: 'RM', shortLabel: 'RM', label: 'Requirement Monitoring' };
const RIC = { value: 'RIC', shortLabel: 'RIC', label: 'Requirement Is Conditional' };
const CL = { value: 'CL', shortLabel: 'CL', label: 'Clarification' };
const E = { value: 'E', shortLabel: 'E', label: 'Exemptions' };
const S = { value: 'S', shortLabel: 'S', label: 'Scope' };
const AT = { value: 'AT', shortLabel: 'AT', label: 'Appendix Text' };
const D = { value: 'D', shortLabel: 'D', label: 'Definition' };

// Groupings
const AIC = [RA, RM, RIC, CL, E, G, S, AT, Z, D];
const AIC_VALUES = AIC.map(c => c.value);

const AIC_GROUPS = [REQUIREMENT, SUPPORTING_INFO, DEFINITION];
const AIC_GROUPS_VALUES = AIC_GROUPS.map(c => c.value);
const EXPANDED_AIC_GROUPS = [REQUIREMENT, SUPPORTING_INFO, DEFINITION, G, Z];
const EXPANDED_AIC_GROUPS_VALUES = EXPANDED_AIC_GROUPS.map(c => c.value);

const REQUIREMENTS = [RA, RM, RIC];
const REQUIREMENTS_VALUES = REQUIREMENTS.map(c => c.value);

const SUPPORTING_INFOS = [CL, E, G, S, AT, Z];
const SUPPORTING_INFOS_VALUES = SUPPORTING_INFOS.map(c => c.value);

export default {
  AIC_GROUPS_VALUES,
  AIC_GROUPS,
  AIC_VALUES,
  EXPANDED_AIC_GROUPS,
  EXPANDED_AIC_GROUPS_VALUES,
  AIC,
  AT,
  CL,
  D,
  DEFINITION,
  E,
  G,
  RA,
  REQUIREMENT,
  REQUIREMENTS_VALUES,
  REQUIREMENTS,
  RIC,
  RM,
  S,
  SUPPORTING_INFO,
  SUPPORTING_INFOS_VALUES,
  SUPPORTING_INFOS,
  Z,
};
