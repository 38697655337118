import React from 'react';

import Loading from '../Loading';

export default function Submit({
  label = 'Submit',
  className = '',
  isLoading = false,
  buttonRef,
  ...restProps
}) {
  return (
    <button
      data-testid="submit"
      className={`button ${className}`}
      ref={buttonRef}
      type="submit"
      {...restProps}
    >
      {label}{' '}
      {isLoading ? (
        <Loading className="margin-left-1" isLoading={true} />
      ) : (
        <i className="fas fa-chevron-right margin-left-1" />
      )}
    </button>
  );
}
