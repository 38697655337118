import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { NavLink } from 'react-router-dom';
import { pageToCursor, cursorToPage, useLocationSearch } from '../../../utils/searchParams';
import { compactObject } from '../../../utils/object';

import Alert from '@material-ui/lab/Alert';
import AssignmentReturned from '@material-ui/icons/AssignmentReturnedOutlined';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';

import Status from '../../Status';
import { STAGE_TASKS_QUERY } from '../queries';
import { default as STATUSES, STATUS_VALUES } from '../../../constants/statuses';

const HEADER_HEIGHT = 78;

export const Task = ({ task, ...rest }) => {
  const { id, group, status, name, description } = task;
  const isBlocked = status === STATUSES.BLOCKED.value;
  const TaskLink = isBlocked ? Box : Link;

  return (
    <Card {...rest}>
      <CardActionArea disabled={isBlocked}>
        <TaskLink component={NavLink} to={`/tasks/${id}`} data-testid="task-link">
          <CardContent>
            <Grid container spacing={2}>
              <Grid data-testid="task-icon" item xs={1}>
                <AssignmentReturned />
              </Grid>
              {group && (
                <Grid data-testid="task-group" item xs={2}>
                  <Typography>Group: {group.name}</Typography>
                </Grid>
              )}
              <Grid data-testid="task-name" item xs={2}>
                <Typography>{name}</Typography>
              </Grid>
              <Grid data-testid="task-description" xs item>
                <Typography>{description}</Typography>
              </Grid>
              <Grid data-testid="task-status" item xs={1} align="right">
                <Status value={status} />
              </Grid>
            </Grid>
          </CardContent>
        </TaskLink>
      </CardActionArea>
    </Card>
  );
};

Task.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    order: PropTypes.number,
    status: PropTypes.oneOf(STATUS_VALUES),
    description: PropTypes.string,
    group: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};

export const TasksList = ({ stageId, tasks, pageCount, page, setPage, ...rest }) => {
  const titleRef = useRef();

  const onPageClick = (_event, page) => {
    window.scrollTo(0, titleRef.current.offsetTop - HEADER_HEIGHT);
    setPage(page);
  };

  return (
    <Box data-testid={`tasks-${stageId}`} {...rest}>
      <Box mb={2}>
        <Typography component="h2" variant="h5" ref={titleRef}>
          <Box display="flex" justifyContent="space-between">
            Tasks
            <Pagination
              count={pageCount}
              onChange={onPageClick}
              page={page}
              showFirstButton
              showLastButton
            />
          </Box>
        </Typography>
      </Box>

      {tasks.map(task => (
        <Task task={task} key={task.id} />
      ))}

      <Box display="flex" justifyContent="center">
        <Pagination
          count={pageCount}
          onChange={onPageClick}
          page={page}
          showFirstButton
          showLastButton
        />
      </Box>
    </Box>
  );
};

TasksList.propTypes = {
  stageId: PropTypes.string.isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.oneOf(STATUS_VALUES).isRequired,
      group: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  pageCount: PropTypes.number,
  page: PropTypes.number,
  setPage: PropTypes.func,
};

const TasksListWithData = ({ stageId }) => {
  const {
    params: { after },
    replaceSearchParams,
  } = useLocationSearch();

  const { loading, data, error } = useQuery(STAGE_TASKS_QUERY, {
    variables: compactObject({ stageId, after }),
    pollInterval: window.ENV.POLL_INTERVAL,
  });

  if (loading) return <LinearProgress />;

  if (error)
    return (
      <Alert severity="error">
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    );

  const { nodes: tasks, pageSize, pageCount } = data.stage.tasks;

  const page = cursorToPage(after, pageSize);

  const setPage = newPage => {
    const newAfter = pageToCursor(newPage, pageSize);
    replaceSearchParams(compactObject({ after: newAfter }));
  };

  return (
    <TasksList
      page={page}
      pageCount={pageCount}
      setPage={setPage}
      stageId={stageId}
      tasks={tasks}
    />
  );
};

TasksListWithData.propTypes = {
  stageId: PropTypes.string.isRequired,
};

export default TasksListWithData;
