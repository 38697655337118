import React from 'react';
import BlockedIcon from '@material-ui/icons/Block';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import CompletedIcon from '@material-ui/icons/CheckCircleOutlined';
import AwaitingConfirmationIcon from '@material-ui/icons/NewReleasesOutlined';
import MdIcon from '../components/MdIcon';

export const STATUS_MAP = {
  BLOCKED: {
    bgcolor: '#757575',
    label: 'Blocked',
    value: 'blocked',
    Icon: props => <BlockedIcon {...props} />,
  },
  STARTED: {
    bgcolor: '#ff9800',
    label: 'Started',
    value: 'started',
    Icon: props => <MdIcon name="pending" {...props} />,
  },
  COMPLETED: {
    bgcolor: '#4caf4f',
    label: 'Completed',
    value: 'completed',
    Icon: props => <CompletedIcon {...props} />,
  },
  ARCHIVED: {
    bgcolor: '#bdbdbd',
    label: 'Archived',
    value: 'archived',
    Icon: props => <MdIcon name="history_toggle_off" {...props} />,
  },
  ERRORED: {
    bgcolor: '#f44335',
    label: 'Errored',
    value: 'errored',
    Icon: props => <ErrorIcon {...props} />,
  },
  AVAILABLE: {
    bgcolor: '#2296f3',
    label: 'Available',
    value: 'available',
    Icon: props => <MdIcon name="not_started" {...props} />,
  },
  AWAITING_CONFIRMATION: {
    bgcolor: '#2296f3',
    label: 'Awaiting Confirmation',
    value: 'awaiting_confirmation',
    Icon: props => <AwaitingConfirmationIcon {...props} />,
  },
};

export const PUBLICATION_STATUSES = {
  ARCHIVED: 'archived',
  DRAFTED: 'drafted',
  HIDDEN: 'hidden',
  PUBLISHED: 'published',
};

export const STATUS_VALUES = Object.values(STATUS_MAP).map(s => s.value);

export default STATUS_MAP;
