import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import TableRow from './TableRow';
import TableHeader from './TableHeader';
import { useColumnsWidth, checkRegulatorName, orderByPublishedDate } from './utils';

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'relative',
  },
  table: {
    width: '100%',
    overflow: 'auto hidden',
  },
  regulatorNameField: {
    width: '20rem',
    display: 'block',
    fontSize: '0.5rem',
    margin: '2rem 0 1.5rem',

    '& .MuiInputBase-root > input': {
      border: 'none',
      boxShadow: 'none',
    },
  },
  baseBox: {
    display: 'flex',
    columnGap: '1rem',
    alignItems: 'center',
  },
});

const columnsConfig = {
  drag: {
    width: 60,
    fixed: true,
  },
  link: {
    width: 60,
    fixed: true,
  },
  title: {
    width: 350,
  },
  date: {
    width: 200,
  },
};

const NoRecordsBox = () => (
  <Paper
    data-testid="no-records-box"
    style={{
      display: 'flex',
      padding: '3rem 1rem',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Typography>There are no records to show for this task</Typography>
  </Paper>
);

const ReorderScrapedDocumentList = ({ task, inputs, completeTask }) => {
  const classes = useStyles();
  const rootRef = useRef(null);
  const [records, setRecords] = useState([]);
  const [dropTarget, setDropTarget] = useState(null);
  const [regulatorName, setRegulatorName] = useState('');
  const columnsWidth = useColumnsWidth(rootRef, columnsConfig);

  const isRegulatorName = useMemo(() => checkRegulatorName(regulatorName), [regulatorName]);

  useEffect(() => {
    const hasOutput = Array.isArray(task.output?.value);
    const records = hasOutput ? task.output.value : orderByPublishedDate(inputs[0].value);
    const regulatorName = hasOutput && records.length > 0 ? records[0].regulator_name || '' : '';

    setRecords(records);
    setRegulatorName(regulatorName.trimStart());
  }, [task, inputs]);

  const handleSubmit = useCallback(
    () =>
      completeTask(records.map(record => ({ ...record, regulator_name: regulatorName.trim() }))),
    [records, regulatorName, completeTask]
  );

  const handleDrag = useCallback(
    ({
      source: {
        data: { rowId: srcRowId },
      },
      location: {
        current: { input, dropTargets },
      },
    }) => {
      if (dropTargets.length === 0) {
        setDropTarget(null);
        return;
      }

      const {
        element,
        data: { rowId: destRowId },
      } = dropTargets[0];

      const rect = element.getBoundingClientRect();
      const rectMidY = rect.top + (rect.bottom - rect.top) / 2;
      const insertPosition = input.clientY < rectMidY ? 'top' : 'bottom';

      setDropTarget({ id: destRowId, srcRowId, insertPosition });
    },
    []
  );

  const handleDrop = useCallback(
    ({
      source: {
        data: { rowIndex: srcRowIndex },
      },
      location: {
        current: { dropTargets },
      },
    }) => {
      if (!dropTarget || dropTargets.length === 0) return;

      let destRowIndex = dropTargets[0].data.rowIndex;

      if (srcRowIndex > destRowIndex) {
        destRowIndex += dropTarget.insertPosition === 'top' ? 0 : 1;
      } else if (srcRowIndex < destRowIndex) {
        destRowIndex -= dropTarget.insertPosition === 'top' ? 1 : 0;
      }

      setRecords(
        reorder({
          list: records,
          startIndex: srcRowIndex,
          finishIndex: destRowIndex,
        })
      );

      setDropTarget(null);
    },
    [records, setRecords, dropTarget, setDropTarget]
  );

  useEffect(() => {
    const args = [
      monitorForElements({
        onDrag: handleDrag,
        onDrop: handleDrop,
      }),
    ];

    if (rootRef.current) {
      args.push(
        autoScrollForElements({
          element: rootRef.current.parentElement,
        })
      );
    }

    return combine(...args);
  }, [handleDrag, handleDrop]);

  return (
    <>
      <Box ref={rootRef} className={classes.root}>
        {(!columnsWidth || !records.length) && <NoRecordsBox />}

        {!!columnsWidth && !!records.length && (
          <>
            <TextField
              fullWidth
              variant="outlined"
              value={regulatorName}
              label="Regulator Name"
              disabled={task.status !== 'started'}
              placeholder="Enter a regulator name"
              className={classes.regulatorNameField}
              InputProps={{ 'data-testid': 'regulator-name-field-input' }}
              onChange={event => setRegulatorName(event.target.value.trimStart())}
            />

            <Paper className={classes.table}>
              <TableHeader columnsWidth={columnsWidth} />

              {records.map((record, index) => (
                <TableRow
                  key={record.originalId}
                  id={record.originalId}
                  index={index}
                  record={record}
                  dropTarget={dropTarget}
                  columnsWidth={columnsWidth}
                  canDrag={task.status === 'started'}
                />
              ))}
            </Paper>
          </>
        )}
      </Box>

      <Box mt={2} className={classes.baseBox}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={!isRegulatorName || task.status !== 'started'}
        >
          <Box>Submit</Box>
        </Button>

        {!isRegulatorName && (
          <Typography variant="subtitle2" style={{ color: '#9d0000' }}>
            Regulator name cannot be blank and can only contain space, dash, underscore, and
            alphanumeric characters
          </Typography>
        )}
      </Box>
    </>
  );
};

export default ReorderScrapedDocumentList;
