import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
  mutation LoginWorker($username: String!, $password: String!) {
    loginWorker(input: { username: $username, password: $password }) {
      token
      errors
    }
  }
`;
