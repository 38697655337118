import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import ErroredStageRestart from '../ErroredStageRestart';
import Status from '../../Status';
import MdIcon from '../../MdIcon';
import { STAGES_QUERY } from '../queries';
import { default as STATUSES, STATUS_VALUES } from '../../../constants/statuses';
import { default as CASTE_VALUES } from '../../../constants/workerCastes';

const MD_ICON_NAME_MAP = {
  human: 'people',
  machine: 'code',
  api: 'api',
};

const Stage = ({ stage, pipelineId, ...rest }) => {
  const {
    name,
    status,
    id: stageId,
    taskType: { workerCaste },
  } = stage;

  const iconName = MD_ICON_NAME_MAP[workerCaste];
  const isBlocked = status === STATUSES.BLOCKED.value;
  const StageLink = isBlocked ? Box : Link;

  return (
    <Card {...rest}>
      <CardActionArea disabled={isBlocked}>
        <StageLink
          to={`/stages/${stageId}`}
          data-testid="stage-link"
          role={`stage-link-${workerCaste}`}
        >
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <MdIcon name={iconName}></MdIcon>
              </Grid>
              <Grid data-testid={'stage-name'} item xs>
                <Typography align="left">{name}</Typography>
              </Grid>
              <Grid data-testid={'stage-status'} item xs={2} align="right">
                <Status value={status} />
              </Grid>
            </Grid>
          </CardContent>
        </StageLink>
      </CardActionArea>
    </Card>
  );
};

Stage.propTypes = {
  stage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    status: PropTypes.oneOf(STATUS_VALUES).isRequired,
    taskType: PropTypes.shape({
      workerCaste: PropTypes.oneOf(CASTE_VALUES).isRequired,
    }),
  }),
};

export const StagesList = ({ pipelineId, stages, ...rest }) => {
  const [showMachineStages, setShowMachineStages] = useState(true);

  const buttonText = `${showMachineStages ? 'Hide' : 'Show'}  Machine Stages `;
  const buttonIconName = showMachineStages ? 'unfold_less' : 'unfold_more';

  const filteredStages = !showMachineStages
    ? stages.filter(s => s.taskType.workerCaste !== 'machine')
    : stages;

  const erroredStage = stages.find(
    s => s.status === 'errored' && ['api', 'machine'].includes(s.taskType.workerCaste)
  );

  return (
    <Box {...rest}>
      {erroredStage && (
        <ErroredStageRestart
          pipelineId={pipelineId}
          stageId={erroredStage.id}
          stageName={erroredStage.name}
          mt={2}
        />
      )}
      <Box data-testid={`stages-${pipelineId}`} mt={4} mb={2}>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography component="h2" variant="h5">
            Stages
          </Typography>
          <Button
            color="secondary"
            data-testid="machine-toggle"
            onClick={() => setShowMachineStages(!showMachineStages)}
          >
            {buttonText}
            <MdIcon name={buttonIconName} />
          </Button>
        </Box>
        {filteredStages.map((s, idx) => (
          <Stage stage={s} key={`stage-${idx}`} idx={idx} pipelineId={pipelineId} />
        ))}
      </Box>
    </Box>
  );
};

StagesList.propTypes = {
  pipelineId: PropTypes.string.isRequired,
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.oneOf(STATUS_VALUES).isRequired,
      taskType: PropTypes.shape({
        workerCaste: PropTypes.oneOf(CASTE_VALUES).isRequired,
      }),
      pipeline: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    })
  ),
};

const StagesListWithData = ({ pipelineId }) => {
  const { loading, error, data } = useQuery(STAGES_QUERY, {
    variables: { pipelineId },
    pollInterval: window.ENV.POLL_INTERVAL,
  });

  if (loading) return <LinearProgress />;
  if (error)
    return (
      <Alert severity="error">
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    );

  return <StagesList pipelineId={pipelineId} stages={data.stages} />;
};

StagesListWithData.propTypes = {
  pipelineId: PropTypes.string.isRequired,
};
export default StagesListWithData;
