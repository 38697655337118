import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',

    '& :nth-child(1)': {
      width: '0.5rem',
      height: '0.5rem',
      borderRadius: '50%',
      position: 'absolute',
      marginLeft: '-0.48rem',
      border: '0.1rem solid #1e88e5',
    },

    '& :nth-child(2)': {
      flexGrow: 1,
      height: '0.1rem',
      background: '#1e88e5',
    },
  },
});

const InsertIndicator = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box />
      <Box />
    </Box>
  );
};

export default InsertIndicator;
