import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';

const useStyles = makeStyles(theme => ({
  progress: {
    margin: 'auto',
    width: '100%',
  },
  colorPrimary: {
    backgroundColor: green[100],
  },
  barColorPrimary: {
    backgroundColor: green[600],
  },
}));

export default function ProgressBar({ label, completed = 0, total = 100, ...restProps }) {
  const classes = useStyles();
  const percentComplete = total === 0 ? 100 : Math.round((completed / total) * 100);
  const { colorPrimary, barColorPrimary } = classes;

  return (
    <Box display="flex" {...restProps}>
      {!!label && (
        <Box pr={2}>
          <Typography variant="body2" align="left" color="textSecondary">
            {label}
          </Typography>
        </Box>
      )}
      <Box display="flex" flexGrow={1}>
        <LinearProgress
          className={classes.progress}
          variant="determinate"
          value={percentComplete}
          classes={{ colorPrimary, barColorPrimary }}
        />
      </Box>
      <Box pl={2}>
        <Typography data-testid="progress-text" variant="body2" align="right" color="textSecondary">
          <span data-testid="percent-complete">{`${percentComplete}%`}</span> (
          <span data-testid="completed-value">{completed}</span>/
          <span data-testid="total-value">{total}</span>)
        </Typography>
      </Box>
    </Box>
  );
}

ProgressBar.propTypes = {
  completed: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  label: PropTypes.string,
};
