import React from 'react';

const TYPE_ICONS = {
  error: {
    icon: 'fa-exclamation-triangle',
    color: 'text-alert',
  },
  warning: {
    icon: 'fa-exclamation-circle',
    color: 'text-warning',
  },
  info: {
    icon: 'fa-info-circle',
    color: 'text-secondary',
  },
  success: {
    icon: 'fa-check-circle',
    color: 'text-success',
  },
};

export default function Alert({
  type = 'success',
  className = '',
  message = '',
  margin = true,
  ...restProps
}) {
  return (
    <span
      className={`display-inline-block ${margin ? 'margin-left-half margin-right-2' : ''} ${
        TYPE_ICONS[type].color
      } ${className}`}
      {...restProps}
    >
      <i className={`margin-right-half fas ${TYPE_ICONS[type].icon}`} />
      {message}
    </span>
  );
}
