import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import STATUS_MAP from '../../constants/statuses';

const useStyles = (bgcolor, showIconOnly) => {
  const styles = {
    root: {
      color: '#FFFFFF',
      background: bgcolor,
    },
    icon: {
      color: '#FFFFFF',
    },
  };

  if (showIconOnly) {
    styles.icon.margin = 'unset';
    styles.root.padding = '0 0.23em';
    styles.label = { display: 'none' };
  }

  return makeStyles(styles)();
};

export default function Status({ value, showText = true, showIcon, variant = 'small', ...rest }) {
  let { label, bgcolor, Icon } = STATUS_MAP[value.toUpperCase()];

  const classes = useStyles(bgcolor, showIcon && !showText);

  const fontSize = variant === 'medium' ? 'default' : variant;

  return (
    <Chip
      {...rest}
      size={variant}
      classes={classes}
      label={showText && label}
      icon={showIcon && <Icon fontSize={fontSize} />}
    />
  );
}

Status.propTypes = {
  showText: PropTypes.bool,
  showIcon: PropTypes.bool,
};
