import React from 'react';

import ActionButton from '../ActionButton';
import styles from './RadioButtons.module.scss';

export default function RadioButtons({
  options = [],
  className = '',
  onClick,
  value,
  clearable,
  isDisabled = false,
  ...restProps
}) {
  return (
    <>
      <div className={`button-group ${className}`} {...restProps}>
        {options.map((o, i) => (
          <button
            disabled={isDisabled}
            key={i}
            className={`button tertiary ${o.value === value ? '' : 'hollow'} ${o.className || ''}`}
            onClick={() => onClick(o.value)}
          >
            {o.label}
          </button>
        ))}
      </div>
      {clearable && value && (
        <ActionButton
          className={styles.times}
          iconClassName="fas fa-times"
          onClick={() => onClick(null)}
        />
      )}
    </>
  );
}
