import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import PipelineIcon from '@material-ui/icons/DeviceHubOutlined';

import ProgressBar from '../../Progress/ProgressBar';

import { PIPELINES_BY_TYPE_QUERY } from '../queries';
import { default as STATUS_MAP, STATUS_VALUES } from '../../../constants/statuses';
import SageLink from '../../SageLink';

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '100%',
  },
  verticleJustify: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

const FOURTEEN_DAYS_AGO = moment().subtract(14, 'days');

const PipelineCard = ({ pipeline, ...restProps }) => {
  const styles = useStyles();
  const color = STATUS_MAP[pipeline.status.toUpperCase()].bgcolor;

  return (
    <Card component={Box} {...restProps}>
      <CardActionArea className={styles.fullHeight}>
        <SageLink to={`/pipelines/${pipeline.id}`} className={styles.fullHeight}>
          <CardContent component={Box} className={clsx(styles.fullHeight, styles.verticleJustify)}>
            <Box display="flex" mb={1}>
              <Box flexShrink={1} p={0.5} pr={2}>
                <PipelineIcon fontSize="large" style={{ color }} />
              </Box>
              <Box>
                <Typography gutterBottom>{pipeline.name}</Typography>
                <Typography variant="body2" color="secondary">
                  {pipeline.pipelineType.name}
                </Typography>
              </Box>
            </Box>
            <ProgressBar
              data-testid="pipeline-card-progress"
              completed={pipeline.progress.completed}
              total={pipeline.progress.total}
              component={Box}
            />
          </CardContent>
        </SageLink>
      </CardActionArea>
    </Card>
  );
};

export const PipelinesPage = ({ pipelineTypes, filterTitle }) => {
  const styles = useStyles();

  return (
    <Container>
      <Typography component="h1" variant="h4" noWrap>
        {filterTitle || 'Pipelines'}
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <SageLink to="/#">Pipelines</SageLink>
        {filterTitle && <Typography color="textPrimary">{filterTitle}</Typography>}
      </Breadcrumbs>

      {!pipelineTypes.length && <Alert severity="info">No Pipelines</Alert>}

      {pipelineTypes.map(pipelineType => (
        <Box key={pipelineType.id} mt={5}>
          <Typography component="h2" variant="h5" gutterBottom>
            {pipelineType.name}
          </Typography>

          <Grid container mt={2} spacing={3} alignItems="stretch">
            {pipelineType.pipelines.nodes.map(pipeline => (
              <Grid item key={pipeline.id} xs={12} sm={6} md={4}>
                <PipelineCard pipeline={pipeline} className={styles.fullHeight} />
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Container>
  );
};

PipelinesPage.propTypes = {
  pipelineTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      pipelines: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string.isRequired,
            pipelineType: PropTypes.shape({
              name: PropTypes.string.isRequired,
            }),
            progress: PropTypes.shape({
              total: PropTypes.number.isRequired,
              completed: PropTypes.number.isRequired,
              label: PropTypes.string,
            }),
            status: PropTypes.oneOf(STATUS_VALUES),
          })
        ).isRequired,
      }).isRequired,
    })
  ).isRequired,
};

const PipelinesPageWithData = ({ statuses, completedAfter, ...restProps }) => {
  const { loading, error, data } = useQuery(PIPELINES_BY_TYPE_QUERY, {
    variables: {
      pipelinesStatuses: statuses,
      pipelinesCompletedAfter: completedAfter,
    },
  });

  if (loading) return <LinearProgress />;
  if (error)
    return (
      <Alert severity="error">
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    );

  const nonEmptyPipelineTypes = data.pipelineTypes.filter(pt => pt.pipelines.nodes.length);

  return <PipelinesPage pipelineTypes={nonEmptyPipelineTypes} {...restProps} />;
};

export const PipelinesInProgressPage = props => (
  <PipelinesPageWithData
    statuses={['started', 'errored']}
    filterTitle="Pipelines In Progress"
    {...props}
  />
);

export const PipelinesRecentlyCompletedPage = props => (
  <PipelinesPageWithData
    statuses={['completed']}
    filterTitle="Pipelines Recently Completed"
    completedAfter={FOURTEEN_DAYS_AGO.format('YYYY-MM-DD')}
    {...props}
  />
);

export default PipelinesPageWithData;
