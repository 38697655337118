import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import GroupEditor from './editor';
import Draggable from '../Draggable';
import DropZone from '../DropZone';
import Alert from '../Alert';
import Collapsible from '../Collapsible';

const GroupWrapper = styled.div`
  ${tw`table w-full px-4 my-4`}
`;

class Group extends Component {
  state = {
    isEditing: false,
  };

  static propTypes = {
    group: PropTypes.object.isRequired,
  };

  handleKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
      this.handleNameChange(e);
    }
  };

  handleDeleteClick = e => {
    e.preventDefault();
    return this.props.groupsStore.removeGroup(this.props.group.id);
  };

  handleNameChange = e => {
    e.preventDefault();
    return this.props.groupsStore.setGroupName(this.props.group.id, e.target.value);
  };

  renderCollapsible = groupedSubjects => {
    const label = `Contains ${groupedSubjects.length} item${
      groupedSubjects.length !== 1 ? 's' : ''
    }`;

    return (
      <Collapsible label={label}>
        {groupedSubjects.map(subject => (
          <Draggable
            subject={subject}
            key={subject.subject_id}
            groupsStore={this.props.groupsStore}
          />
        ))}
      </Collapsible>
    );
  };

  render() {
    const groupedSubjects = this.props.groupsStore.subjects.filter(subject =>
      subject.group_key ? subject.group_key === this.props.group.id : null
    );
    return (
      <GroupWrapper>
        <GroupEditor
          name={this.props.group.name}
          isEditing={this.state.isEditing}
          onChange={this.handleNameChange}
          onKeyDown={this.handleKeyDown}
          handleDeleteClick={this.handleDeleteClick}
        />
        <DropZone group={this.props.group} groupsStore={this.props.groupsStore}>
          <div>
            {groupedSubjects.length === 0 ? (
              <Alert type="warning" message="There are no items in this group yet" />
            ) : (
              this.renderCollapsible(groupedSubjects)
            )}
          </div>
        </DropZone>
      </GroupWrapper>
    );
  }
}

export default observer(Group);
