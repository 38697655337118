import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import SageLink from '../../SageLink';

import Alert from '@material-ui/lab/Alert';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import MdIcon from '../../MdIcon';
import Status from '../../Status';
import ProgressBar from '../../Progress/ProgressBar';
import StagesListWithData from '../../Stages/StagesList';
import { PIPELINE_QUERY } from '../queries';
import { STATUS_VALUES } from '../../../constants/statuses';
import useCopyToClipboard from '../../../utils/copyToClipboard';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArchivePipelineWithData from '../ArchivePipeline';

export const PipelinePage = ({ pipelineId, pipeline }) => {
  const copyToClipboard = useCopyToClipboard();

  if (!pipeline) return <Alert severity="info">No Pipeline with ID {pipelineId}</Alert>;

  return (
    <Container>
      <Box data-testid={`pipeline-${pipelineId}`} display="flex" justifyContent="space-between">
        <Typography component="h1" variant="h4" noWrap style={{ display: 'flex', width: '70%' }}>
          {pipeline.name}
        </Typography>
        <Box>
          <Status value={pipeline.status} data-testid="pipeline-status" variant="medium" />
          <IconButton onClick={() => copyToClipboard(pipelineId)}>
            <MdIcon mh={1} name="copy" />
          </IconButton>
          <PipelineMenu pipeline={pipeline} />
        </Box>
      </Box>
      <Breadcrumbs aria-label="breadcrumb">
        <SageLink to="/">Pipelines</SageLink>
        <Typography>{pipeline.pipelineType.name}</Typography>
        <Typography color="textPrimary">{pipeline.name}</Typography>
      </Breadcrumbs>
      <ProgressBar
        data-testid="pipeline-page-progress"
        label="Pipeline Progress"
        completed={pipeline.progress.completed}
        total={pipeline.progress.total}
        component={Box}
      />
      <StagesListWithData pipelineId={pipelineId} />
    </Container>
  );
};

PipelinePage.propTypes = {
  pipeline: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    status: PropTypes.oneOf(STATUS_VALUES),
  }).isRequired,
};

const PipelineMenu = ({ pipeline }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="archive-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="pipeline-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <ArchivePipelineWithData pipeline={pipeline} />
      </Menu>
    </>
  );
};

const PipelinePageWithData = () => {
  const { pipelineId } = useParams();

  const { loading, error, data } = useQuery(PIPELINE_QUERY, {
    variables: { id: pipelineId },
    pollInterval: window.ENV.POLL_INTERVAL,
  });

  if (loading) return <LinearProgress />;
  if (error)
    return (
      <Alert severity="error">
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    );

  return !data.pipeline ? (
    <Alert severity="info">No Pipeline with ID {pipelineId}</Alert>
  ) : (
    <PipelinePage pipelineId={pipelineId} pipeline={data.pipeline} />
  );
};

export default PipelinePageWithData;
