import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { decorate, computed } from 'mobx';
import { Submit } from '../../Legacy/Forms';
import Box from '@material-ui/core/Box';
import ApiErrors from '../../Legacy/ApiErrors';

class ImportJson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: '',
      fileContent: '',
      errors: [],
      isCompletingTask: false,
    };
  }

  componentDidMount() {
    if (this.task.output && this.task.output.file) {
      this.setState({
        fileName: this.task.output.file.fileName,
        fileContent: this.task.output.file.fileContent,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.savedProgress !== this.props.savedProgress) {
      this.setState({
        fileName: this.state.fileName || '',
        fileContent: this.state.fileContent || '',
      });
    }
  }

  get fileDataIsValid() {
    return this.state.fileContent && this.state.fileName;
  }

  get isLoading() {
    return this.props.isLoadingTask;
  }

  get isCompletingTask() {
    return this.props.isCompletingTask;
  }

  get task() {
    return this.props.task;
  }

  handleFileChange = event => {
    const file = event.target.files[0];

    this.setState({ fileName: file.name });
    const reader = new FileReader();
    reader.onload = e => {
      this.setState({ fileContent: e.target.result });
    };
    reader.readAsText(file);
  };

  submit = () => {
    this.props
      .completeTask([
        {
          original_id: this.state.fileName,
          regulator_name: this.state.fileName,
          document_contents: this.state.fileContent,
        },
      ])
      .catch(error => {
        this.setState({ errors: [...this.state.errors, error.message] });
      });
  };

  renderSubmit() {
    return (
      <Box>
        <Submit
          className="button"
          disabled={
            this.task.status === 'completed' || this.isCompletingTask || !this.fileDataIsValid
          }
          isLoading={this.isCompletingTask}
          onClick={() => !this.isCompletingTask && this.submit()}
        />
        <ApiErrors errors={this.state.errors} />
      </Box>
    );
  }

  render() {
    const isTaskCompleted = this.task.status === 'completed';

    return (
      <div className="grid-x">
        <label htmlFor="file-input">
          {isTaskCompleted ? (
            <div>{this.state.fileName || this.task.output.value['file_name']}</div>
          ) : (
            <input
              id="file-input"
              type="file"
              accept=".json"
              onChange={this.handleFileChange}
              disabled={isTaskCompleted}
            />
          )}
        </label>
        <div className="cell medium-12 padding-top-2">{this.renderSubmit()}</div>
      </div>
    );
  }
}

export default observer(
  decorate(ImportJson, {
    task: computed,
    isCompletingTask: computed,
    isLoading: computed,
  })
);
