import React from 'react';
import { observer } from 'mobx-react';
import { decorate, computed } from 'mobx';

import { RadioButtons } from '../../Legacy/Forms';
import ActionButton from '../../Legacy/ActionButton';
import styles from './index.module.scss';

import RulesTask from '../RulesTask';

class PreassignAppendixText extends RulesTask {
  isSubjectBased = true;

  get taskInputValue() {
    return this.inputs.find(i => i.result_type.slug === 'SubjectRulesForPreassign').value;
  }

  setAicAndGroup(rule, aic) {
    this.setRuleValues(rule, {
      aic,
      aic_group: aic === 'AT' ? 'SI' : '',
    });
  }

  // Submission
  // ---------------------------------------------------------------------------
  get output() {
    return {
      rules: this.mergedRules.map(rule => ({
        temp_id: rule.temp_id,
        comments: this.getOutputComments(rule.comments, rule.newComments),
        aic: rule.aic || '',
        aic_group: rule.aic_group || '',
      })),
    };
  }

  // Render Methods
  // ---------------------------------------------------------------------------

  renderActions() {
    return (
      <div className="margin-top-1">
        <button
          disabled={this.isComplete}
          className="button secondary small margin-bottom-0"
          onClick={() => this.setAllRulesValues({ aic: 'AT', aig_group: 'SI' })}
        >
          <i className="fas fa-angle-double-down margin-right-half"></i> Mark Subject as Appendix
          Text
        </button>
        <button
          disabled={this.isComplete}
          className="button tertiary small margin-left-1 margin-bottom-0"
          onClick={() => this.setAllRulesValues({ aic: undefined, aig_group: undefined })}
        >
          <i className="fas fa-redo-alt margin-right-half"></i> Reset
        </button>
      </div>
    );
  }

  renderRules() {
    return (
      <div>
        <div className="grid-x grid-margin-x margin-top-2">
          <div className="cell medium-2">
            <strong>Rule Number</strong>
          </div>
          <div className="cell medium-4 height-0">
            <strong>Code</strong>
          </div>
          <div className="cell medium-6">
            <strong>Content</strong>
          </div>
        </div>

        {this.mergedRules.map(rule => (
          <div key={rule.temp_id} className={`grid-x grid-margin-x ${styles.ruleWrapper}`}>
            <div className="cell medium-2">
              <p>
                <em>{rule.parent_rule_number}</em>
              </p>
            </div>
            <div className="cell medium-4">
              <RadioButtons
                value={rule.aic || ''}
                isDisabled={this.isComplete}
                onClick={aic => this.setAicAndGroup(rule, aic)}
                options={[
                  {
                    label: 'Other',
                    value: '',
                  },
                  {
                    label: 'Appendix Text',
                    value: 'AT',
                  },
                ]}
              />
            </div>
            <div className="cell auto">
              <div className={styles.rule}>{this.renderLinesAsParagraphs(rule.base_content)}</div>
            </div>
            <div className="cell shrink">
              <ActionButton
                className={`margin-bottom-half ${rule.is_combined && 'invisible'}`}
                iconClassName="fas fa-search"
                onClick={() => this.jumpToRule(rule)}
              />
              <br />
              {!rule.is_combined &&
                this.renderRuleComments(rule, rule.newComments, rule.isCommentsVisible)}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default observer(
  decorate(PreassignAppendixText, {
    output: computed,
    taskInputValue: computed,
  })
);
