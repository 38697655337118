import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import pink from '@material-ui/core/colors/pink';
import indigo from '@material-ui/core/colors/indigo';

const defaultTheme = createMuiTheme();

export default createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          padding: defaultTheme.spacing(9, 0, 0, 9),
        },
      },
    },
    MuiBreadcrumbs: {
      root: {
        margin: defaultTheme.spacing(1, 0, 2),
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '.8em',
      },
    },
    MuiCard: {
      root: {
        marginBottom: defaultTheme.spacing(1.5),
      },
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: defaultTheme.spacing(2),
        },
      },
    },
    MuiTabs: {
      root: {
        marginBottom: defaultTheme.spacing(2),
      },
      flexContainer: {
        borderBottom: '1px solid lightgrey',
      },
    },
    MuiTypography: {
      h1: { marginBottom: defaultTheme.spacing(2) },
      h2: { marginBottom: defaultTheme.spacing(2) },
      h3: { marginBottom: defaultTheme.spacing(2) },
    },
    MuiLink: {
      root: { color: indigo[900] },
    },
  },
  props: {
    MuiLink: {
      underline: 'none',
    },
  },
  palette: {
    primary: {
      main: blue[600],
    },
    secondary: {
      main: pink[500],
    },
  },
});
