import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import ActionAlert from '../../ActionAlert';
import { TASK_QUERY, START_TASK_MUTATION } from '../queries';

const TaskRestart = ({ taskId, ...restProps }) => {
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const restart = true;
  const [startTask, { loading }] = useMutation(START_TASK_MUTATION, {
    variables: { taskId, restart },
    refetchQueries: [
      {
        query: TASK_QUERY,
        variables: { id: taskId, restart: restart },
      },
    ],
  });

  const toggleOpen = () => setIsOpen(!isOpen);

  const handleCancel = () => {
    setError(null);
    setIsOpen(!isOpen);
  };

  const handleConfirm = async () => {
    setError(null);

    try {
      const { data } = await startTask();

      if (!data.startTask.success) setError('Encountered an error while restarting task');
      else setIsOpen(!isOpen);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <ActionAlert
      alertText="Task has been completed"
      actionText="RESTART THIS TASK"
      actionHandler={toggleOpen}
      actionConfirm={{
        error,
        isOpen,
        loading,
        handleCancel,
        handleConfirm,
        title: 'Restart Task',
        description: 'Are you sure you want to restart this task?',
      }}
      {...restProps}
    />
  );
};

TaskRestart.propTypes = {
  taskId: PropTypes.string.isRequired,
};

export default TaskRestart;
