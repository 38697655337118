import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { decorate, computed, intercept } from 'mobx';
import styled from 'styled-components';
import tw from 'tailwind.macro';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ReplayIcon from '@material-ui/icons/ReplayOutlined';
import FastForwardIcon from '@material-ui/icons/FastForwardOutlined';

import Loading from '../../Legacy/Loading';
import Draggable from '../../Legacy/Draggable';
import DropZone from '../../Legacy/DropZone';
import { Submit } from '../../Legacy/Forms';
import Alert from '../../Legacy/Alert';
import ApiErrors from '../../Legacy/ApiErrors';
import Group from '../../Legacy/Group';
import GroupsStore from '../../../stores/GroupsStore';

const FixedHeightContainer = styled.div`
  ${tw`w-1/2`}
  max-height: calc(80vh - 120px);
  padding-bottom: 150px !important;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const Title = styled.p`
  ${tw`font-bold text-lg`}
`;
class GroupSubjects extends Component {
  state = {
    submitHeight: 0,
  };

  constructor(props) {
    super(props);
    this.groupsStore = new GroupsStore();
  }

  get groupsAreValid() {
    return this.groupsStore.ungroupedSubjects.length === 0;
  }

  get isLoading() {
    return this.props.isLoadingTask;
  }

  get isCompletingTask() {
    return this.props.isCompletingTask;
  }

  get task() {
    return this.props.task;
  }

  get inputs() {
    return this.props.inputs;
  }

  get taskInputValue() {
    return this.inputs.find(i => i.result_type.slug === 'SubjectsForGrouping').value;
  }

  get output() {
    return this.groupsStore.subjects.map(subj => ({
      ...subj,
      group: subj.group || null,
      group_key: subj.group_key || null,
    }));
  }

  componentDidMount() {
    this.saveProgressOnChange();

    if (this.props.savedProgress) {
      const { subjects, groups } = this.props.savedProgress;
      this.groupsStore.setSubjects(subjects);
      this.groupsStore.setGroups(groups);
    } else {
      this.groupsStore.setSubjects(this.taskInputValue);
    }

    this.setState({
      submitHeight: document.getElementById('render-submit')
        ? document.getElementById('render-submit').clientHeight
        : '100px',
    });
  }

  saveProgressOnChange = () => {
    intercept(this.groupsStore, 'subjects', change => {
      this.props.saveProgress({
        subjects: change.newValue,
        groups: this.groupsStore.groups,
      });
      return change;
    });
    intercept(this.groupsStore, 'groups', change => {
      this.props.saveProgress({
        subjects: this.groupsStore.subjects,
        groups: change.newValue,
      });
      return change;
    });
  };

  autoAssignByModule = () => this.groupsStore.autoAssignByModule();

  autoAssignToOneGroup = () => this.groupsStore.autoAssignToOneGroup();

  reset = () => this.groupsStore.reset();

  submit() {
    this.props.completeTask(this.output).catch(console.log);
  }

  renderSubmit() {
    const marginLeft = 72; // width of closed left navbar
    return (
      <Box style={{ marginLeft: `${marginLeft}px` }}>
        <hr />
        <Submit
          className="margin-right-2"
          disabled={
            this.task.status === 'completed' || this.isCompletingTask || !this.groupsAreValid
          }
          isLoading={this.isCompletingTask}
          onClick={() => !this.isCompletingTask && this.submit()}
        />
        {this.groupsStore.ungroupedSubjects.length !== 0 && (
          <Alert type="warning" message="All subjects must have a group." />
        )}
        <ApiErrors errors={this.props.errors} />
      </Box>
    );
  }

  render() {
    if (this.isLoading || !this.inputs.length)
      return <Loading className="margin-auto margin-top-2" type="large" isLoading={true} />;

    return (
      <>
        <div className="grid-x">
          <Button
            children="Assign by Module"
            color="secondary"
            variant="outlined"
            onClick={() => this.autoAssignByModule()}
            startIcon={<FastForwardIcon />}
            component={Box}
            mr={1}
          />
          <Button
            children="Assign to One Group"
            color="secondary"
            variant="outlined"
            onClick={() => this.autoAssignToOneGroup()}
            startIcon={<FastForwardIcon />}
            component={Box}
            mr={1}
          />
          <Button
            children="Reset"
            color="default"
            variant="outlined"
            onClick={() => this.reset()}
            startIcon={<ReplayIcon />}
          />
        </div>
        <div className="grid-x">
          <FixedHeightContainer>
            <DropZone groupsStore={this.groupsStore} group={null}>
              <div style={tw`text-lg font-bold py-2`}>
                <Title>Modules/Subjects</Title>
              </div>

              {this.groupsStore.ungroupedSubjects.length === 0 ? (
                <Alert
                  type="success"
                  message="You've finished the Grouping stage. Click the 'Submit' button below to continue."
                />
              ) : (
                this.groupsStore.ungroupedSubjects.map(subject => {
                  if (!subject.group) {
                    return (
                      <Draggable
                        subject={subject}
                        key={subject.subject_id}
                        groupsStore={this.groupsStore}
                      />
                    );
                  }
                  return false;
                })
              )}
            </DropZone>
          </FixedHeightContainer>

          {/* END LEFT RESPONSIVE CELL*/}
          <FixedHeightContainer data-testid="grouping-container">
            <div style={tw`text-lg font-bold mx-4 pb-2 pt-4 mt-2`}>
              <Title>Groups</Title>
            </div>
            {this.groupsStore.groups.map((group, idx) => (
              <Group group={group} groupsStore={this.groupsStore} key={idx} />
            ))}
            <div style={tw`px-4 my-4`}>
              <Submit
                label="Add Group"
                className="button secondary"
                onClick={() =>
                  this.groupsStore.addGroup({
                    name: `Group ${this.groupsStore.groups.length + 1}`,
                  })
                }
              />
            </div>
          </FixedHeightContainer>
        </div>

        <div className="grid-container position-fixed-bottom bg-white" id="render-submit">
          {this.renderSubmit()}
        </div>
        {this.props.showOutput && (
          <div>
            <pre>{JSON.stringify(this.state.ruleInputs, null, 2)}</pre>
            <pre>{JSON.stringify(this.output, null, 2)}</pre>
          </div>
        )}
      </>
    );
  }
}

export default observer(
  decorate(GroupSubjects, {
    inputs: computed,
    isCompletingTask: computed,
    isLoading: computed,
    groupsAreValid: computed,
    output: computed,
    task: computed,
    taskInputValue: computed,
  })
);
