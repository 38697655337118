import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { decorate, computed } from 'mobx';
import Form from 'react-jsonschema-form';

class Generic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: props.savedProgress || {},
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.savedProgress !== this.props.savedProgress) {
      this.setState({ formData: this.props.savedProgress || {} });
    }
  }

  get task() {
    return this.props.task;
  }

  async change({ formData }) {
    await this.props.saveProgress(formData);
    this.setState({ formData });
  }

  submit({ formData }) {
    if (this.task.status !== 'completed')
      this.props.completeTask(formData, formData).catch(console.log);
  }

  render() {
    return (
      <div>
        <Form
          disabled={this.task.status === 'completed'}
          schema={this.task.output_type.schema}
          formData={this.state.formData}
          onSubmit={vals => this.submit(vals)}
          onError={console.log}
        />
      </div>
    );
  }
}

export default observer(
  decorate(Generic, {
    task: computed,
  })
);
