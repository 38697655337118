import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { LocalStorage } from 'ttl-localstorage';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';

import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import FastForwardIcon from '@material-ui/icons/FastForward';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListIcon from '@material-ui/icons/List';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import Typography from '@material-ui/core/Typography';

import ConfirmDialog from '../../ConfirmDialog';
import Status from '../../Status';
import TaskNavbar from '../TaskNavbar';
import TaskRestart from '../TaskRestart';
import {
  TASK_QUERY,
  TASK_INPUT_QUERY,
  COMPLETE_TASK_MUTATION,
  START_TASK_MUTATION,
} from '../queries';
import ProgressBar from '../../Progress/ProgressBar';
import TasksDrawerWithData from '../TasksDrawer';

import throttle from '../../../utils/throttle';
import useCopyToClipboard from '../../../utils/copyToClipboard';
import { STATUS_VALUES, STATUS_MAP } from '../../../constants/statuses';
import * as taskTypeComponents from '../../TaskTypes';
import { STAGES_QUERY, STAGE_QUERY } from '../../Stages/queries';
import { PIPELINE_QUERY } from '../../Pipelines/queries';
import MdIcon from '../../MdIcon';
import SageLink from '../../SageLink';

const TASK_NAV_WIDTH = 400;
const THIRTY_DAYS = 30 * 24 * 60 * 60;

const useStyles = makeStyles(theme => ({
  container: {
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  content: {
    width: '100%',
    height: '100%',
    padding: '1.5rem',
    position: 'relative',
    overflow: 'hidden auto',
    left: ({ drawerIsOpen, drawerWidth }) => (drawerIsOpen ? `${drawerWidth}px` : '0px'),
  },
  drawerToggle: {
    padding: 0,
    margin: 0,
    marginRight: theme.spacing(1),
  },
}));

const TaskComponent = ({ task, completeTask, savedProgress, inputs, ...restProps }) => {
  const Component = taskTypeComponents[(task.task_type?.slug)] || taskTypeComponents.Generic;

  const history = useHistory();

  const goToNextTask = () => history.push(`/tasks/${task.nextId}`);
  const goToStage = () => history.push(`/stages/${task.stage.id}`);
  const moveOnFromTask = () => (!!task.nextId ? goToNextTask() : goToStage());

  const throwResponseErrors = response => {
    const { errors } = response.data.completeTask;

    if (errors) throw new Error(errors);
  };
  const clearLocalProgress = async () => LocalStorage.removeKey(task.id);

  const handleSaveProgress = throttle(
    progress => LocalStorage.put(task.id, progress, THIRTY_DAYS),
    1000
  );

  const handleCompleteTask = (outputValue, workerInput) =>
    completeTask({ variables: { id: task.id, outputValue, workerInput } })
      .then(throwResponseErrors)
      .then(clearLocalProgress)
      .then(moveOnFromTask)
      .catch(error => console.log(error));

  return (
    <Component
      completeTask={handleCompleteTask}
      saveProgress={handleSaveProgress}
      savedProgress={savedProgress}
      task={task}
      inputs={inputs}
      {...restProps}
    />
  );
};

export const TaskPage = ({
  task,
  drawerWidth = TASK_NAV_WIDTH,
  completeTask,
  savedProgress,
  shouldAutoComplete,
  setShouldAutoComplete,
  ...restProps
}) => {
  const { id, group, name, inputs, pipeline, stage, status, task_type } = task;

  const [drawerIsOpen, setDrawerOpen] = useState(false);
  const classes = useStyles({ drawerWidth, drawerIsOpen });
  const containerRef = useRef();
  const submitRef = useRef();
  const copyToClipboard = useCopyToClipboard();

  const parent = group || stage || {};
  const progress = {
    label: group ? 'Group Progress' : 'Stage Progress',
    total: parent.progress.total,
    completed: parent.progress.completed,
  };

  const taskIsCompleted = status === STATUS_MAP.COMPLETED.value;
  const taskProgressHasLoaded = savedProgress !== false;
  const stageIsNotCompleted = stage.status !== STATUS_MAP.COMPLETED.value;
  const stageIsManualProgress = !stage.autoProgress;
  const restartable = taskIsCompleted && stageIsNotCompleted && stageIsManualProgress;

  const [autoCompleteConfirmIsOpen, setAutoCompleteConfirmIsOpen] = useState(false);

  const onAutoCompleteCancel = () => {
    setAutoCompleteConfirmIsOpen(false);
  };

  const onAutoCompleteConfirm = () => {
    setAutoCompleteConfirmIsOpen(false);
    setShouldAutoComplete(true);
  };

  useEffect(() => {
    if (!taskIsCompleted && shouldAutoComplete && submitRef) submitRef.current.click();
  }, [task, taskIsCompleted, shouldAutoComplete, submitRef]);

  return (
    <Container maxWidth="xl" ref={containerRef} className={classes.container}>
      {taskProgressHasLoaded && <div data-testid="task-progress-has-loaded" />}

      <TasksDrawerWithData
        groupId={group?.id}
        stageId={stage.id}
        containerRef={containerRef}
        isOpen={drawerIsOpen}
        width={drawerWidth}
        handleClose={() => setDrawerOpen(false)}
      />
      <Box className={classes.content}>
        <Box display="flex" mb={1} justifyContent="space-between">
          <Typography variant="h4" component="h1">
            {name}
          </Typography>
          <Box>
            <Status value={status} variant="medium" data-testid="task-status" />
            <IconButton onClick={() => copyToClipboard(id)}>
              <MdIcon mh={1} name="copy" />
            </IconButton>
          </Box>
        </Box>
        <Breadcrumbs aria-label="breadcrumb">
          <SageLink to="/">Pipelines</SageLink>
          <Typography>{pipeline.pipelineType.name}</Typography>
          <SageLink data-testid="pipeline-page-link" to={`/pipelines/${pipeline.id}`}>
            {pipeline.name}
          </SageLink>
          <SageLink data-testid="stage-page-link" to={`/stages/${stage.id}`}>
            {task_type.name}
          </SageLink>
          {group && <Typography>Group: {group.name}</Typography>}
          <Typography color="textPrimary">{name}</Typography>
        </Breadcrumbs>

        <Box display="flex" alignItems="center" mb={4}>
          <IconButton onClick={() => setDrawerOpen(!drawerIsOpen)} size="small">
            {drawerIsOpen ? <MenuOpenIcon /> : <ListIcon />}
          </IconButton>
          <ProgressBar
            data-testid="task-page-progress"
            {...progress}
            component={Box}
            flexGrow={1}
            ml={1}
          />
          <IconButton size="small" onClick={() => setAutoCompleteConfirmIsOpen(true)}>
            <FastForwardIcon />
          </IconButton>
          <ConfirmDialog
            isOpen={autoCompleteConfirmIsOpen}
            onCancel={onAutoCompleteCancel}
            onConfirm={onAutoCompleteConfirm}
            title="Are you sure?"
            description="This will automatically complete this and any following tasks. This is not an easy action to undo."
          ></ConfirmDialog>
        </Box>

        <TaskNavbar my={2} task={task} />
        {restartable && <TaskRestart mb={2} taskId={id} />}

        {task_type.workerCaste === 'human' ? (
          <TaskComponent
            completeTask={completeTask}
            inputs={inputs}
            savedProgress={savedProgress}
            submitRef={submitRef}
            task={task}
            {...restProps}
          />
        ) : (
          <TaskOutput output={task.output?.value} />
        )}
      </Box>
    </Container>
  );
};

const TaskOutput = ({ output }) => (
  <Card>
    <CardContent>
      <Typography variant="h6" component="h6">
        Output Value (JSON)
      </Typography>
      <hr />
      <SyntaxHighlighter language="json" showLineNumbers={true} wrapLongLines={true}>
        {JSON.stringify(output, null, 2)}
      </SyntaxHighlighter>
    </CardContent>
  </Card>
);

TaskPage.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    group: PropTypes.shape({
      id: PropTypes.string,
      key: PropTypes.string,
      name: PropTypes.string,
    }),
    status: PropTypes.oneOf(STATUS_VALUES),
  }).isRequired,
};

const withTaskAutoComplete = Component => props => {
  const [shouldAutoComplete, setShouldAutoComplete] = useState(false);

  return (
    <Component
      shouldAutoComplete={shouldAutoComplete}
      setShouldAutoComplete={setShouldAutoComplete}
      {...props}
    />
  );
};

const withTask = Component => props => {
  const { taskId } = useParams();

  const { loading, error, data } = useQuery(TASK_QUERY, {
    variables: { id: taskId },
  });

  const refetchQueries = [
    { query: TASK_QUERY, variables: { id: taskId } },
    { query: STAGE_QUERY, variables: { id: data?.task?.stage.id } },
    { query: STAGES_QUERY, variables: { pipelineId: data?.task?.pipeline.id } },
    { query: PIPELINE_QUERY, variables: { id: data?.task?.pipeline.id } },
  ];

  const [completeTask] = useMutation(COMPLETE_TASK_MUTATION, { refetchQueries });

  if (loading) return <LinearProgress />;
  if (error) return <Alert severity="error">{JSON.stringify(error)}</Alert>;

  return !data.task ? (
    <Alert severity="info">Task with ID {taskId} not found</Alert>
  ) : (
    <Component completeTask={completeTask} task={data.task} {...props} />
  );
};

const withTaskStatus = Component => ({ task, shouldAutoComplete, ...restProps }) => {
  const [startTask, { loading, error }] = useMutation(START_TASK_MUTATION, {
    refetchQueries: [{ query: TASK_QUERY, variables: { id: task.id } }],
  });

  useEffect(() => {
    (async () => {
      if (!shouldAutoComplete && task.status === 'available')
        await startTask({ variables: { taskId: task.id, restart: false } }).catch(console.log);
    })();
  }, [shouldAutoComplete, task, startTask]);

  if (loading) return <LinearProgress />;
  if (error) return <Alert severity="error">{JSON.stringify(error)}</Alert>;

  return <Component task={task} shouldAutoComplete={shouldAutoComplete} {...restProps} />;
};

const withTaskProgress = Component => ({ task, ...restProps }) => {
  const { taskId } = useParams();

  const [localSavedProgress, setLocalSavedProgress] = useState(false);

  useEffect(() => {
    LocalStorage.get(taskId).then(val => setLocalSavedProgress(val));
  }, [taskId]);

  const savedProgress = localSavedProgress || task.workerInput;

  return <Component task={task} savedProgress={savedProgress} {...restProps} />;
};

const withTaskInputs = Component => ({ task, ...restProps }) => {
  const queries = task.inputIds.map(id => useQuery(TASK_INPUT_QUERY, { variables: { id } }));

  if (queries.find(q => q.loading)) return <LinearProgress />;

  const inputs = queries.map(q => q.data.result);

  return <Component task={task} inputs={inputs} {...restProps} />;
};

export default withTaskAutoComplete(
  withTask(withTaskInputs(withTaskProgress(withTaskStatus(TaskPage))))
);
