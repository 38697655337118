import { gql } from '@apollo/client';

export const PIPELINE_QUERY = gql`
  query Pipeline($id: ID!) {
    pipeline(id: $id) {
      id
      name
      pipelineType {
        id
        name
      }
      progress
      status
    }
  }
`;

export const PIPELINES_BY_TYPE_QUERY = gql`
  query PipelinesByType($pipelinesStatuses: [FlowStatus!], $pipelinesCompletedAfter: String) {
    pipelineTypes(orderBy: "name") {
      id
      name
      pipelines(statuses: $pipelinesStatuses, completedAfter: $pipelinesCompletedAfter) {
        nodes {
          id
          name
          pipelineType {
            id
            name
          }
          progress
          status
        }
      }
    }
  }
`;

export const PIPELINE_TYPES_QUERY = gql`
  query PipelineTypes($status: PublicationStatus) {
    pipelineTypes(status: $status, orderBy: "name") {
      id
      name
      status
    }
  }
`;

export const START_PIPELINE_MUTATION = gql`
  mutation StartPipeline($pipelineName: String!, $pipelineTypeId: ID!) {
    startPipeline(input: { pipelineName: $pipelineName, pipelineTypeId: $pipelineTypeId }) {
      pipelineId
    }
  }
`;

export const ARCHIVE_PIPELINE_MUTATION = gql`
  mutation ArchivePipeline($id: ID!) {
    archivePipeline(input: { id: $id }) {
      success
    }
  }
`;
