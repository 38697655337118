import { observable, computed, decorate, action } from 'mobx';
import uuid from 'uuid/v4';

export class Group {
  constructor({ id, name }) {
    this.id = id || uuid();
    this.name = name || `Group ${this.id}`;
    this.isEditing = false;
  }

  setName(name) {
    return (this.name = name);
  }
}

const DEFAULT_GROUPS = [new Group({ name: `One` }), new Group({ name: 'Two' })];

class GroupsStore {
  // Observables
  // ---------------------------------------------------------------------------
  subjects = [];
  selected = [];
  groups = DEFAULT_GROUPS;

  // Computed
  // ---------------------------------------------------------------------------

  get ungroupedSubjects() {
    return this.subjects.filter(subject => {
      return !subject.group;
    });
  }

  // Actions
  // ---------------------------------------------------------------------------

  reset = () => {
    this.groups = DEFAULT_GROUPS;
    this.subjects = this.subjects.map(subject => {
      delete subject.group;
      delete subject.group_key;

      return subject;
    });
  };

  // ------ Subject Handlers --------------
  setSubjects = subjects => (this.subjects = [...subjects]);

  handleDropSelected = (e, group) => {
    if (e.preventDefault) e.preventDefault();
    const singleId = e.dataTransfer.getData('text');

    if (this.selected.length > 0) {
      this.selected.forEach(id => this.assignToGroup(id, group));
    } else {
      this.assignToGroup(singleId, group);
    }
    this.clearSelected();
  };

  // ------ Group Handlers --------------
  setGroups = groups => (this.groups = [...groups]);

  assignToGroup = (idToUpdate, group) => {
    const groupInfo = group
      ? { group_key: group.id, group: group.name }
      : { group_key: null, group: null };

    return (this.subjects = this.subjects.map(subj => {
      return subj.subject_id === idToUpdate ? { ...subj, ...groupInfo } : { ...subj };
    }));
  };

  addGroup(params) {
    return this.groups.push(new Group(params));
  }

  removeGroup(groupId) {
    // remove groups from tasks where applicable
    this.subjects.map(subj =>
      subj.group && subj.group.id === groupId ? this.assignToGroup(subj.subject_id) : null
    );

    return (this.groups = this.groups.filter(group => group.id !== groupId));
  }

  getGroupName(id) {
    return this.groups.filter(group => group.id === id).name;
  }

  setGroupName(groupId, newName) {
    return (this.groups = this.groups.map(group => {
      if (group.id === groupId) {
        this.updateGroupedSubjNames(groupId, newName);

        return { ...group, name: newName };
      } else {
        return { ...group };
      }
    }));
  }

  updateGroupedSubjNames(groupId, name) {
    return (this.subjects = this.subjects.map(subj => {
      return !subj.group || subj.group_key !== groupId ? subj : { ...subj, group: name };
    }));
  }

  autoAssignByModule() {
    this.setGroups([]);
    let group;
    let groupCount = 0;
    let newGroups = [];

    this.subjects = this.subjects.map((subject, idx) => {
      if (this.subjects[idx - 1]?.ascent_module_name !== subject.ascent_module_name) {
        groupCount++;
        group = new Group({ name: groupCount.toString().padStart(3, '0') });
        newGroups.push(group);
      }

      return { ...subject, group_key: group.id, group: group.name };
    });
    this.groups = newGroups;
  }

  autoAssignToOneGroup() {
    this.setGroups([]);

    const group = new Group({ name: '001' });

    this.subjects = this.subjects.map(subject => ({
      ...subject,
      group_key: group.id,
      group: group.name,
    }));

    this.setGroups([group]);
  }

  // ------ Selection Handlers --------------
  addToSelected(id) {
    return (this.selected = [...this.selected, id]);
  }

  removeFromSelected(id) {
    return (this.selected = this.selected.filter(itemId => itemId !== id));
  }

  clearSelected() {
    return (this.selected = []);
  }
}

decorate(GroupsStore, {
  groups: observable,
  selected: observable,
  subjects: observable,

  ungroupedSubjects: computed,

  addGroup: action,
  addToSelected: action,
  autoAssignByModule: action,
  clearSelected: action,
  createNewGroup: action,
  getGroupName: action,
  removeFromSelected: action,
  removeGroup: action,
  reset: action,
  setGroupName: action,
  setGroups: action,
  setSubjects: action,
});

export default GroupsStore;
