import React from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { GROUPS_QUERY } from './queries';

const useStyles = makeStyles({
  text: {
    width: '100%',
    textAlign: 'center',
  },
  card: {
    marginBottom: 0,
  },
});

const renderGroupIcon = isComplete =>
  isComplete ? (
    <AssignmentTurnedInOutlinedIcon data-testid="group-complete-icon" />
  ) : (
    <AssignmentOutlinedIcon data-testid="group-icon" />
  );

const Group = ({ group }) => {
  const classes = useStyles();
  const {
    name,
    firstTask,
    progress: { completed, total },
  } = group;

  return (
    <Grid item sm={3} md={2} lg={1}>
      <Card classes={{ root: classes.card }}>
        <CardActionArea disabled={!firstTask}>
          <Box
            p={1.2}
            display="flex"
            to={`/tasks/${firstTask?.id}`}
            component={firstTask ? Link : Box}
          >
            {renderGroupIcon(completed === total)}
            <Typography className={classes.text}>
              {name} ({completed}/{total})
            </Typography>
          </Box>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export const Groups = ({ groups, ...restProps }) => {
  if (groups.length === 0) return null;

  return (
    <Box {...restProps} data-testid="groups-display">
      <Box mb={2}>
        <Typography component="h2" variant="h5">
          Groups
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {groups.map(group => (
          <Group key={`group-${group.id}`} group={group} />
        ))}
      </Grid>
    </Box>
  );
};

const GroupsWithData = ({ stageId, ...restProps }) => {
  const { loading, error, data } = useQuery(GROUPS_QUERY, {
    variables: { stageId: stageId },
    pollInterval: window.ENV.POLL_INTERVAL,
  });

  if (loading) return <LinearProgress role="progressbar" />;
  if (error)
    return (
      <Alert severity="error">
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    );

  return <Groups groups={data.groups} {...restProps} />;
};
export default GroupsWithData;
