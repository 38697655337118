import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ActionButton from '../ActionButton';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { colors } from '../../../tailwind';

const ActionWrapper = styled.div`
  position: absolute;
  right: -10px;
  top: 30%;
`;

const DragWrapper = styled.div`
  ${tw`border-solid border border-medium-gray rounded my-3 relative`}
  cursor: pointer;
  background-color: ${props =>
    props.isSelected ? '#15866f' : props.isDragging ? colors['light-gray'] : colors['barely-gray']}
  color: ${props => (props.isSelected ? 'white' : 'initial')}
  &::after {
    ${tw`absolute flex bg-warning font-bold text-white rounded-full px-3 py-1`}
   ${props =>
     props.numSelected > 1 &&
     props.isDragging &&
     `content: ' ${props.numSelected} ';
    width: 30px;
    height: 30px;
    background-color: ${colors['warning']};
    bottom: -10px;
    right: -10px;`}
`;

const DragDefaults = {
  onDragStart: (e, id) => {
    e.dataTransfer.dropEffect = 'move'; // See docs on HTML DataTransfer object.
    e.dataTransfer.setData('text', id);
  },

  onDragEnd: e => {
    e.dataTransfer.effectAllowed = 'move'; // See docs on HTML DataTransfer object.
  },
};

class Draggable extends Component {
  state = {
    isDragging: false,
    isSelected: false,
  };

  static propTypes = {
    subject: PropTypes.object,
    children: PropTypes.object,
  };

  toggleDragging = () =>
    this.setState(prevState => ({
      isDragging: !prevState.isDragging,
    }));

  toggleSelected = forceSelection =>
    this.setState(
      prevState => ({
        isSelected: forceSelection || !prevState.isSelected,
      }),
      () =>
        this.state.isSelected
          ? this.props.groupsStore.addToSelected(this.props.subject.subject_id)
          : this.props.groupsStore.removeFromSelected(this.props.subject.subject_id)
    );

  handleClick = e => {
    e.preventDefault();
    this.toggleSelected();
  };

  render() {
    let { onDragStart, onDragEnd } = DragDefaults;
    let { state } = this;
    return (
      <DragWrapper
        numSelected={this.props.groupsStore.selected.length}
        isSelected={state.isSelected}
        isDragging={state.isDragging}
        onMouseDown={this.toggleDragging}
        onDragStart={e => {
          this.toggleDragging();
          onDragStart(e, this.props.subject.subject_id);
        }}
        onDragEnd={e => {
          this.toggleDragging();
          onDragEnd(e);
        }}
        onClick={this.handleClick}
        draggable
      >
        <div style={tw`table w-full py-2 relative`}>
          <div style={tw`table-cell w-10 h-full mx-2 align-middle text-center text-medium-gray `}>
            <i className={`fas ${state.isSelected ? 'fa-check' : 'fa-grip-horizontal'}`} />
          </div>
          <div style={tw`table-cell w-90 h-full mx-2 align-middle py-2`}>
            <p style={tw`mb-1`}>
              <span style={tw`font-bold mb-1`}>{this.props.subject.ascent_module_name}</span>
              <br />
              {this.props.subject.subject_name}
            </p>
          </div>
          {this.props.subject.group && (
            <ActionWrapper>
              {' '}
              <ActionButton
                iconClassName="fas fa-times"
                onClick={() => this.props.groupsStore.assignToGroup(this.props.subject.subject_id)}
              />{' '}
            </ActionWrapper>
          )}
        </div>
      </DragWrapper>
    );
  }
}

export default observer(Draggable);
