import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useApiClient } from '../../clients/api';

const POLL_INTERVAL = 15000;
const GIT_SHA_HEADER_KEY = 'x-git-sha';
const DEFAULT_RELOAD = () => window.location.reload();

const UpdateBoundary = ({
  children,
  defaultGitSHA,
  pollInterval = POLL_INTERVAL,
  reload = DEFAULT_RELOAD,
}) => {
  const apiClient = useApiClient();
  const [gitSHA, setGitSHA] = useState(defaultGitSHA);

  useEffect(() => {
    const fetchGitSHA = () =>
      apiClient
        .get('/health_check')
        .then(({ headers }) => setGitSHA(headers[GIT_SHA_HEADER_KEY]))
        .catch(console.log);

    const interval = setInterval(fetchGitSHA, pollInterval);
    return () => clearInterval(interval);
  }, [pollInterval, apiClient]);

  useEffect(() => {
    if (gitSHA !== defaultGitSHA) reload();
  }, [gitSHA, defaultGitSHA, reload]);

  return children;
};

UpdateBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  defaultGitSHA: PropTypes.string.isRequired,
  pollInterval: PropTypes.number,
  reload: PropTypes.func,
};

export default UpdateBoundary;
