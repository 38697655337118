import React, { useState, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import Alert from '@material-ui/lab/Alert';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import MenuItem from '@material-ui/core/MenuItem';

import ConfirmDialog from '../../ConfirmDialog';
import { PIPELINE_QUERY, ARCHIVE_PIPELINE_MUTATION } from '../queries';

const useStyles = makeStyles(theme => ({
  menuItem: {
    display: 'flex',
  },
  ArchiveOutlinedIcon: {
    width: theme.spacing(4),
  },
}));

const ArchivePipeline = forwardRef(({ pipeline }, ref) => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const [archivePipeline] = useMutation(ARCHIVE_PIPELINE_MUTATION, {
    variables: {
      id: pipeline.id,
    },
    refetchQueries: [
      {
        query: PIPELINE_QUERY,
        variables: {
          id: pipeline.id,
        },
      },
    ],
  });

  const toggleOpen = () => setIsOpen(!isOpen);
  const handleCancel = () => {
    setError(null);
    toggleOpen();
  };
  const handleConfirm = async () => {
    try {
      const {
        data: {
          archivePipeline: { success },
        },
        error,
      } = await archivePipeline();
      if (error || !success) return setError('Encountered an error while completing stage');
      setIsOpen(!isOpen);
      setError(null);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <MenuItem
        ref={ref}
        onClick={toggleOpen}
        className={classes.menuItem}
        disabled={pipeline.status === 'archived'}
      >
        <ArchiveOutlinedIcon className={classes.ArchiveOutlinedIcon} size="large" />
        Archive
      </MenuItem>
      <ConfirmDialog
        isOpen={isOpen}
        confirmText={'Archive'}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        title="Are you sure you want to archive this pipeline?"
        description={pipeline.name}
      >
        {error && (
          <Alert severity="error">
            <pre>{JSON.stringify(error, null, 2)}</pre>
          </Alert>
        )}
      </ConfirmDialog>
    </>
  );
});

ArchivePipeline.propTypes = {
  pipeline: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  archivePipeline: PropTypes.func,
};

export default ArchivePipeline;
