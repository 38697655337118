import React, { Component } from 'react';
import ActionButton from '../ActionButton';
import styled from 'styled-components';
import tw from 'tailwind.macro';

const LabelWrapper = styled.div`
  ${tw`flex`}
`;

class Collapsible extends Component {
  state = {
    isOpen: true,
  };

  togglePanel = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  render() {
    return (
      <>
        <LabelWrapper>
          <ActionButton
            style={tw`mr-2`}
            data-testid="toggle"
            iconClassName={`fas fa-${this.state.isOpen ? 'caret-down' : 'caret-right '}`}
            onClick={this.togglePanel}
          />
          <p style={tw`font-bold text-sm m-0`}>{this.props.label}</p>
        </LabelWrapper>
        {this.state.isOpen ? <>{this.props.children}</> : null}
      </>
    );
  }
}

export default Collapsible;
