import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import MdIcon from '../../MdIcon';
import PropTypes from 'prop-types';
import { default as ConsistencyIcon } from '@material-ui/icons/PeopleOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import codes from '../../../constants/codes';

const useStyles = makeStyles({
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const ValidationFlagInfo = ({ flag }) => {
  const classes = useStyles();

  const { code, confidence } = flag;
  const IS_AIC_GROUP = code === 'aic_group_confidence';
  const percentage = confidence ? `${Math.round(confidence * 100)}%` : null;

  const Icon = () =>
    IS_AIC_GROUP ? (
      <MdIcon data-testid="confidence-icon" name={'memory'} />
    ) : (
      <ConsistencyIcon data-testid="consistency-icon" />
    );
  const tooltip = IS_AIC_GROUP
    ? `Our model was ${percentage} confident in this prediction`
    : 'Someone marked a similar rule as a requirement';

  return (
    <Tooltip title={tooltip} placement="top" className={classes.tooltip} arrow>
      <IconButton aria-label={tooltip} size="small" className={classes.button}>
        <Icon className={classes.icon} />
        {!!percentage && <Box pl={0.5}>{percentage}</Box>}
      </IconButton>
    </Tooltip>
  );
};

const ConfirmAic = ({ task, inputs, completeTask, submitRef, ...rest }) => {
  const rule = inputs.find(i => i.result_type.slug === 'RuleForAicReview').value;
  const handleSubmit = value =>
    completeTask({ temp_id: rule.temp_id, worker_designated_aic_group: value });

  return (
    <Box {...rest}>
      <Box display="flex" mb={2} justifyContent="center">
        <Box mr={1}>
          <Box display="flex" justifyContent="space-evenly">
            {rule.validation_flags.map((val, key) => (
              <ValidationFlagInfo key={key} flag={val} />
            ))}
          </Box>
          <Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              buttonRef={submitRef}
              onClick={() => handleSubmit(codes.REQUIREMENT.value)}
            >
              <Box mx={1}>Requirement</Box>
            </Button>
          </Box>
        </Box>
        <Box display="flex" alignItems="flex-end">
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            onClick={() => handleSubmit(rule.aic_group)}
          >
            <Box mx={1}>
              {codes.EXPANDED_AIC_GROUPS.find(a => a.value === rule.aic_group).label}
            </Box>
          </Button>
        </Box>
      </Box>

      <Card>
        <CardContent>
          <Box fontStyle="italic" mb={3}>
            <Typography>{rule.parent_rule_title}</Typography>
            <Typography>{rule.parent_rule_number}</Typography>
          </Box>
          {!rule.base_content
            ? 'No Rule Body found.'
            : rule.base_content.split('\n\n').map((c, i) => <Typography key={i}>{c}</Typography>)}
        </CardContent>
      </Card>
    </Box>
  );
};

ConfirmAic.propTypes = {
  completeTask: PropTypes.func.isRequired,
  task: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      result_type: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }),
      value: PropTypes.shape({
        temp_id: PropTypes.string.isRequired,
        validation_flags: PropTypes.arrayOf(
          PropTypes.shape({
            code: PropTypes.oneOf(['aic_group_confidence', 'intra_ruleset_consistency']).isRequired,
            description: PropTypes.string.isRequired,
            confidence: PropTypes.number,
            similar_rules: PropTypes.arrayOf(PropTypes.string),
          })
        ),
        aic_group: PropTypes.oneOf(codes.AIC_GROUPS_VALUES).isRequired,
        parent_rule_title: PropTypes.string.isRequired,
        parent_rule_number: PropTypes.string.isRequired,
        base_content: PropTypes.string.isRequired,
      }),
    })
  ),
};

export default ConfirmAic;
