import { gql } from '@apollo/client';

export const COMPLETE_TASK_MUTATION = gql`
  mutation CompleteTask($id: ID!, $outputValue: JSON!, $workerInput: JSON) {
    completeTask(input: { id: $id, outputValue: $outputValue, workerInput: $workerInput }) {
      task {
        id
        name
        status
        prevId
        nextId
      }
      errors
    }
  }
`;

export const TASK_QUERY = gql`
  query Task($id: ID!) {
    task(id: $id) {
      id
      description
      group {
        id
        name
        progress
      }
      inputIds
      name
      nextId
      order
      output {
        value
      }
      output_type: outputType {
        slug
        schema
      }
      pipeline {
        id
        name
        pipelineType {
          name
        }
      }
      prevId
      stage {
        id
        progress
        autoProgress
        status
      }
      status
      task_type: taskType {
        allowResubmission
        name
        slug
        workerCaste
      }
      workerInput
    }
  }
`;

export const STAGE_TASKS_QUERY = gql`
  query StageTasks($stageId: ID!, $first: Int, $after: String) {
    stage(id: $stageId) {
      id
      tasks(first: $first, after: $after) {
        pageCount
        pageSize
        pageInfo {
          endCursor
          startCursor
        }
        nodes {
          id
          description
          group {
            name
          }
          name
          order
          status
        }
      }
    }
  }
`;

export const DRAWER_GROUP_PROGRESS_QUERY = gql`
  query Group($id: ID!) {
    group(id: $id) {
      id
      key
      name
      progress
    }
  }
`;

export const DRAWER_STAGE_PROGRESS_QUERY = gql`
  query Stage($id: ID!) {
    stage(id: $id) {
      id
      progress
      taskType {
        name
      }
    }
  }
`;

export const DRAWER_TASKS_QUERY = gql`
  query Tasks($stageId: ID, $groupId: ID) {
    tasks(stageId: $stageId, groupId: $groupId, orderBy: "created_at") {
      id
      name
      order
      status
    }
  }
`;

export const START_TASK_MUTATION = gql`
  mutation StartTask($taskId: ID!, $restart: Boolean) {
    startTask(input: { id: $taskId, restart: $restart }) {
      success
    }
  }
`;

export const TASK_INPUT_QUERY = gql`
  query Result($id: ID!) {
    result(id: $id) {
      id
      value
      result_type: resultType {
        slug
      }
    }
  }
`;
