import { gql } from '@apollo/client';

export const STAGE_QUERY = gql`
  query Stage($id: ID!) {
    stage(id: $id) {
      id
      pipeline {
        id
        name
        pipelineType {
          name
        }
      }
      name
      progress
      status
      tasksCount
      taskType {
        workerCaste
      }
    }
  }
`;

export const STAGES_QUERY = gql`
  query Stages($pipelineId: ID!) {
    stages(pipelineId: $pipelineId, orderBy: "created_at") {
      id
      name
      status
      taskType {
        workerCaste
      }
    }
  }
`;

export const COMPLETE_STAGE_MUTATION = gql`
  mutation CompleteStage($stageId: ID!, $confirmed: Boolean) {
    completeStage(input: { id: $stageId, confirmed: $confirmed }) {
      success
    }
  }
`;

export const RESTART_ERRORED_STAGE_MUTATION = gql`
  mutation RestartErroredStage($stageId: ID!) {
    restartErroredStage(input: { id: $stageId }) {
      success
    }
  }
`;
