import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SageLink from '../../SageLink';
import sageMascotIcon from '../../../assets/images/sage-mascot.png';
import { useAuth } from '../../Auth';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: 'black',
    background: 'white',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    paddingRight: theme.spacing(3),
  },
  menuButton: {
    marginRight: theme.spacing(4.5),
  },
  menuButtonHidden: {
    display: 'none',
  },
  sageTitle: {
    flexGrow: 1,
  },
  sageLink: {
    color: 'black',
    textDecoration: 'none',
  },
  sageIcon: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginRight: theme.spacing(1.5),
    verticalAlign: 'text-bottom',
  },
  startButton: {
    display: 'flex',
    padding: theme.spacing(1.2),
    fontSize: theme.spacing(1.8),
    marginTop: theme.spacing(1.8),
    marginRight: theme.spacing(2.5),
  },
  startButtonIcon: {
    height: theme.spacing(1.8),
  },
}));

export default function Header({ isDrawerOpen, setDrawerOpen }) {
  const classes = useStyles();
  const { logout } = useAuth();

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, isDrawerOpen && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          data-testid="header-drawer-toggler"
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => setDrawerOpen(true)}
          className={clsx(classes.menuButton, isDrawerOpen && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Typography component="div" variant="h6" className={classes.sageTitle}>
          <SageLink to="/" className={classes.sageLink}>
            <img src={sageMascotIcon} className={classes.sageIcon} alt="farmer" />
            Sage
          </SageLink>
        </Typography>
        <SageLink to="/" onClick={logout}>
          Log Out
        </SageLink>
      </Toolbar>
    </AppBar>
  );
}
