import React, { useMemo, useState, useCallback } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PaginationItem from '@material-ui/lab/PaginationItem';
import TableContainer from '@material-ui/core/TableContainer';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    marginBottom: 0,
  },
  tableRow: {
    verticalAlign: 'top',
  },
  baseBox: {
    display: 'flex',
    alignItems: 'center',
    margin: '2rem auto 0',
    justifyContent: 'space-between',
  },
  paginationBox: {
    display: 'flex',
    columnGap: '2rem',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const snakeToTitleCase = str =>
  str
    .trim()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const renderWithLineBreaks = content => {
  if (!content) return null;

  // Split content into lines and render each line with a <br /> for proper spacing.
  return content.split('\n\n').map((line, index) => (
    <React.Fragment key={`line-${index}`}>
      {line}
      {index < content.split('\n\n').length - 1 && <br />}
    </React.Fragment>
  ));
};

const CustomPagination = ({
  page,
  pageSize,
  pageCount,
  rowsCount,
  totalCount,
  onPageChange,
  ...rest
}) => {
  const startPosition = rowsCount <= 0 ? 0 : (page - 1) * pageSize + 1;
  const endPosition = rowsCount <= 0 ? 0 : startPosition + rowsCount - 1;

  const handleChange = (_, page) => {
    if (onPageChange) onPageChange(page);
  };

  return (
    <Box {...rest}>
      <Typography>
        Showing: {startPosition} - {endPosition} of {totalCount}
      </Typography>

      <Pagination
        page={page}
        color="primary"
        count={pageCount}
        variant="outlined"
        onChange={handleChange}
        renderItem={props => (
          <PaginationItem {...props} disabled={props.disabled || props.page === page} />
        )}
      />
    </Box>
  );
};

const ApproveCsvData = ({ task, inputs, completeTask }) => {
  const classes = useStyles();

  const csvData = useMemo(() => inputs[0].value, [inputs]);
  const displayedAttributes = useMemo(
    () => [
      'regulator_name',
      'ascent_module_name',
      'subject_name',
      'parent_rule_number',
      'parent_rule_title',
      'base_content',
      'starts_at',
      'ends_at',
    ],
    []
  );

  // Map column names to their index for ordering
  const columnIndices = useMemo(() => {
    if (!csvData || !csvData[0] || !displayedAttributes) return [];

    const headerRow = csvData[0];
    return displayedAttributes.map(attr => headerRow.indexOf(attr));
  }, [csvData, displayedAttributes]);

  // Transform headers to Title Case
  const columns = useMemo(() => displayedAttributes.map(attr => snakeToTitleCase(attr)), [
    displayedAttributes,
  ]);

  const formattedRows = useMemo(() => {
    const rows = csvData.slice(1); // Exclude the header row
    return rows.map(row => columnIndices.map(index => (index >= 0 ? row[index] : '')));
  }, [csvData, displayedAttributes]);

  // Maybe later via task params on the backend
  // We can also allow selecting columns to render
  const pageSize = useMemo(() => 20, []);

  const totalCount = useMemo(() => formattedRows.length, [formattedRows]);
  const initRows = useMemo(() => formattedRows.slice(0, pageSize), [formattedRows, pageSize]);
  const pageCount = useMemo(() => Math.ceil(totalCount / pageSize), [totalCount, pageSize]);

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(initRows);

  const handlePageChange = useCallback(
    page => {
      setPage(page);

      const startIndex = (page - 1) * pageSize;
      const EndIndex = startIndex + pageSize;

      setRows(formattedRows.slice(startIndex, EndIndex));

      window.scrollTo(0, 0);
    },
    [pageSize, formattedRows, setPage, setRows]
  );

  return (
    <Box>
      <CustomPagination
        page={page}
        pageSize={pageSize}
        pageCount={pageCount}
        rowsCount={rows.length}
        totalCount={totalCount}
        style={{ margin: '2rem 0' }}
        onPageChange={handlePageChange}
        className={classes.paginationBox}
      />

      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow role="header">
              {columns.map((column, index) => (
                <TableCell key={`column-${index}`}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow role="row" className={classes.tableRow} key={`row-${rowIndex}`}>
                {row.map((value, columnIndex) => (
                  <TableCell key={`row-${rowIndex}-column-${columnIndex}`}>
                    {columns[columnIndex] === 'Base Content' ? renderWithLineBreaks(value) : value}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>{' '}
        </Table>
      </TableContainer>

      <Box className={classes.baseBox}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={task.status !== 'started'}
          onClick={() => completeTask(csvData)}
        >
          <Box mx={1}>Approve</Box>
        </Button>

        <CustomPagination
          page={page}
          pageSize={pageSize}
          pageCount={pageCount}
          rowsCount={rows.length}
          totalCount={totalCount}
          onPageChange={handlePageChange}
          className={classes.paginationBox}
        />
      </Box>
    </Box>
  );
};

export default ApproveCsvData;
