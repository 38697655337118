import React, { createContext, useContext } from 'react';
import axios from 'axios';

export const ApiClientContext = createContext({ client: null });

export const ApiClientProvider = ({ children, client }) => (
  <ApiClientContext.Provider value={{ client }}>{children}</ApiClientContext.Provider>
);

export const makeApiClient = args =>
  axios.create({
    baseURL: window.location.origin,
    ...args,
  });

export const useApiClient = () => useContext(ApiClientContext).client;
