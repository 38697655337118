import React from 'react';

const Checkbox = ({ label, onChange, disabled = false, isChecked, ...restProps }) => {
  return (
    <label {...restProps}>
      <input type="checkbox" disabled={disabled} onChange={onChange} checked={isChecked} />
      {label}
    </label>
  );
};
export default Checkbox;
