import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

import ConfirmDialog from '../ConfirmDialog';

const ActionConfirm = ({
  error,
  loading,
  title,
  description,
  isOpen,
  handleCancel,
  handleConfirm,
}) => (
  <ConfirmDialog
    isOpen={isOpen}
    onCancel={handleCancel}
    onConfirm={handleConfirm}
    title={title}
    description={description}
  >
    {loading && <LinearProgress role="progressbar" />}
    {error && (
      <Alert severity="error">
        <pre>{error}</pre>
      </Alert>
    )}
  </ConfirmDialog>
);

const ActionAlert = ({
  alertText,
  actionText,
  actionHandler = () => {},
  actionConfirm,
  ...restProps
}) => (
  <Box
    severity="info"
    variant="filled"
    component={Alert}
    action={
      <Button color="inherit" onClick={() => actionHandler()}>
        <Typography variant="button">{actionText}</Typography>
      </Button>
    }
    {...restProps}
  >
    <Typography>{alertText}</Typography>
    {actionConfirm && <ActionConfirm {...actionConfirm} />}
  </Box>
);

ActionAlert.propTypes = {
  alertText: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
  actionHandler: PropTypes.func,
  actionConfirm: PropTypes.shape({
    error: PropTypes.string,
    loading: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    isOpen: PropTypes.bool,
    handleCancel: PropTypes.func,
    handleConfirm: PropTypes.func,
  }),
};

export default ActionAlert;
