import React from 'react';
import { observer } from 'mobx-react';
import { decorate, computed } from 'mobx';

import { RadioButtons } from '../../Legacy/Forms';
import ActionButton from '../../Legacy/ActionButton';
import Alert from '../../Legacy/Alert';
import codes from '../../../constants/codes';
import styles from './index.module.scss';

import RulesTask from '../RulesTask';

class ExpandedAssignAndCombine extends RulesTask {
  isSubjectBased = true;

  // Props
  // ---------------------------------------------------------------------------
  get taskInputValue() {
    return this.inputs.find(i => i.result_type.slug === 'SubjectRulesForExpandedAssign').value;
  }

  getGroupAic(rule) {
    return rule.is_combined
      ? ''
      : rule.aic_group || rule.aic_group_input || rule.aic_consolidated || '';
  }

  getAic(rule) {
    if (rule.is_combined) {
      return '';
    } else if (rule.aic) {
      return rule.aic;
    } else {
      switch (this.getGroupAic(rule)) {
        case 'D':
          return 'D';
        case 'G':
          return 'G';
        case 'Z':
          return 'Z';
        default:
          return '';
      }
    }
  }

  mergeRule(rawRule, inputs) {
    const prediction = (rawRule.scop_prediction || '').toUpperCase();

    const isCombined = inputs.is_combined !== undefined ? inputs.is_combined : prediction === 'C';

    return {
      ...rawRule,
      ...inputs,
      base_content: inputs.base_content || rawRule.base_content,
      is_combined: isCombined,
      rule_number: `${rawRule.parent_rule_number}${inputs.child_rule_number || ''}`.trim(),
    };
  }

  get mergedFlatRules() {
    let rules = [];

    this.mergedRules.forEach((mergedRule, index) => {
      if (index === 0) {
        mergedRule.is_combined = false;
        mergedRule.scop_prediction = '';
      }

      rules.push(mergedRule);
      (mergedRule.splits || []).forEach(child => rules.push(child));
    });

    return rules;
  }

  prevFlatRule(temp_id) {
    const ruleIdx = this.flatRuleIndex(temp_id);
    return ruleIdx >= 0 && this.mergedFlatRules[ruleIdx - 1];
  }

  flatRuleIndex(temp_id) {
    return this.mergedFlatRules.findIndex(r => r.temp_id === temp_id);
  }

  // Rule Actions
  // ---------------------------------------------------------------------------

  splitRuleAtWord(rule, wordIdx) {
    const words = rule.base_content.split(' ');

    const newValues = {
      is_cutting: false,
      base_content: words.slice(0, wordIdx).join(' '),
    };

    const newRule = {
      prop_rule_temp_id: rule.prop_rule_temp_id || rule.temp_id,
      split_from_temp_id: rule.temp_id,
      temp_id: `${rule.temp_id}_${wordIdx}`,
      is_new: true,
      is_combined: false,
      is_cutting: false,
      base_content: words.slice(wordIdx).join(' '),
      parent_rule_number: rule.parent_rule_number,
    };

    this.setRuleValues(rule, newValues, () => this.insertRule(rule, newRule));
  }

  insertRule(rule, newRule) {
    const propRule = rule.is_new
      ? this.rawRules.find(r => r.temp_id === rule.prop_rule_temp_id)
      : rule;

    const splits = this.ruleSplits(propRule.temp_id);

    const insertIdx = rule.is_new ? splits.findIndex(r => r.temp_id === rule.temp_id) + 1 : 0;

    const newSplits = this.insertIntoArray(splits, newRule, insertIdx);

    this.setRuleValues(propRule, { splits: newSplits });
  }

  ruleSplits(temp_id) {
    return this.state.ruleInputs[temp_id].splits || [];
  }

  combineRule(rule) {
    return rule.is_new
      ? this.mergeRuleUp(rule)
      : this.setRuleValues(rule, { is_combined: !rule.is_combined });
  }

  mergeRuleUp(rule) {
    const propRule = this.rawRules.find(r => r.temp_id === rule.prop_rule_temp_id);
    const splits = this.ruleSplits(propRule.temp_id);
    const splitsIdx = splits.findIndex(r => r.temp_id === rule.temp_id);
    const prevFlatRule = splitsIdx > 0 ? splits[splitsIdx - 1] : propRule;
    const prevRule = this.mergedRulesMap[prevFlatRule.temp_id];

    this.setRuleValues(
      prevRule,
      { base_content: `${prevRule.base_content} ${rule.base_content}` },
      () => this.removeSplitRule(propRule, rule)
    );
  }

  removeSplitRule(rule, splitRule) {
    this.setRuleValues(rule, {
      splits: this.removeFromArray(this.ruleSplits(rule.temp_id), splitRule, 'temp_id'),
    });
  }

  // Submission
  // ---------------------------------------------------------------------------

  get output() {
    return {
      rules: this.mergedRules.map((rule, idx) => {
        const rulesCombinedIntoThisRule = [];
        let nextIdx = idx + 1;
        while (nextIdx < this.mergedRules.length && this.mergedRules[nextIdx].is_combined) {
          rulesCombinedIntoThisRule.push(this.mergedRules[nextIdx]);
          nextIdx++;
        }

        return {
          temp_id: rule.temp_id,
          base_content: rule.base_content,
          comments: [
            this.getOutputComments(rule.comments, rule.newComments),
            ...rulesCombinedIntoThisRule.map(cRule =>
              this.getOutputComments(cRule.comments, cRule.newComments)
            ),
          ]
            .filter(c => c)
            .join('\n\n'),
          content: rule.base_content,
          SCOP: rule.is_combined ? 'C' : '',
          aic: this.getAic(rule),
          aic_group: this.getGroupAic(rule),
          child_rule_number: rule.is_combined
            ? null
            : rule.child_rule_number
            ? rule.rule_number
            : null,
          splits: (rule.splits || []).map(splitRule => ({
            temp_id: splitRule.temp_id,
            base_content: splitRule.base_content,
            comments: [this.getOutputComments(splitRule.comments, splitRule.newComments)]
              .filter(c => c)
              .join('\n\n'),
            content: splitRule.base_content,
            child_rule_number: splitRule.child_rule_number ? splitRule.rule_number : null,
            aic: this.getAic(splitRule),
            aic_group: this.getGroupAic(splitRule),
          })),
        };
      }),
    };
  }

  get errors() {
    let errors = [];

    const codesAssigned = this.mergedFlatRules.map(r => !!(this.getGroupAic(r) || r.is_combined));
    const allCodesAssigned = codesAssigned.indexOf(false) === -1;

    if (!allCodesAssigned) errors.push('All rules must have an assigned code.');

    return errors;
  }

  // Potential Utility Functions
  // ---------------------------------------------------------------------------

  insertIntoArray(arr, item, idx) {
    return [].concat(arr.slice(0, idx), [item], arr.slice(idx));
  }

  removeFromArray(arr, item, idField) {
    const idx = arr.findIndex(i => i[idField] === item[idField]);
    return [].concat(arr.slice(0, idx), arr.slice(idx + 1));
  }

  // Render Methods
  // ---------------------------------------------------------------------------

  renderRules() {
    return (
      <div>
        <div className="grid-x grid-margin-x margin-top-2">
          <div className="cell medium-2">
            <strong>Rule Number</strong>
          </div>
          <div className="cell medium-4 height-0">
            <strong>Code</strong>
          </div>
          <div className="cell medium-6">
            <strong>Content</strong>
          </div>
        </div>

        {this.mergedFlatRules.map(rule => (
          <div
            key={rule.temp_id}
            className={`grid-x grid-margin-x ${styles.ruleWrapper} ${
              rule.is_combined ? styles.ruleWrapperCombined : ''
            }`}
          >
            {!rule.is_combined && (
              <div className="cell medium-12">
                <p>
                  <em>{rule.rule_number}</em>
                </p>
              </div>
            )}
            <div className="cell medium-2">{this.renderRuleChildRule(rule)}</div>
            <div className="cell medium-4 height-0">{this.renderRuleCodes(rule)}</div>
            <div className="cell auto">{this.renderRuleContent(rule)}</div>
            <div className="cell shrink">
              <ActionButton
                className={`margin-bottom-half ${rule.is_combined && 'invisible'}`}
                iconClassName="fas fa-search"
                onClick={() => this.jumpToRule(rule)}
              />
              <br />
              {!rule.is_combined &&
                this.renderRuleComments(rule, rule.newComments, rule.isCommentsVisible)}
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderRuleChildRule(rule) {
    if (rule.is_combined || rule.aic === 'AT') return null;

    return (
      <input
        type="text"
        placeholder="Child Rule"
        defaultValue={rule.child_rule_number}
        disabled={this.isComplete}
        onChange={e => this.setRuleValues(rule, { child_rule_number: e.currentTarget.value })}
      />
    );
  }

  renderRuleContent(rule) {
    const prevFlatRule = this.prevFlatRule(rule.temp_id);
    const ruleIdx = this.flatRuleIndex(rule.temp_id);
    const isPrevDifferentParent =
      prevFlatRule && rule.parent_rule_number !== prevFlatRule.parent_rule_number;
    const showCombineAlert = rule.is_combined && isPrevDifferentParent;
    const isPrevAppendixText = prevFlatRule && prevFlatRule.aic === 'AT';

    return (
      <div
        className={`${styles.rule} ${rule.is_combined && styles.ruleCombined} ${
          showCombineAlert ? styles.ruleCombinedWarning : ''
        }`}
      >
        {showCombineAlert && (
          <Alert className={styles.combineAlert} type="warning" message="Different Parent Rules" />
        )}

        {!this.isComplete && rule.is_cutting
          ? rule.base_content.split(' ').map((c, idx) => (
              <span
                key={idx}
                className={idx > 0 ? styles.word : ''}
                onClick={() => idx > 0 && this.splitRuleAtWord(rule, idx)}
              >
                {idx > 0 && <span className={styles.separator}>&nbsp;</span>}
                {c}
              </span>
            ))
          : rule.base_content}

        {!this.isComplete && ruleIdx > 0 && !isPrevAppendixText && (
          <div className={styles.combineButton} onClick={() => this.combineRule(rule)}>
            {rule.is_combined ? <i className="fas fa-sort" /> : <i className="fas fa-sort-up" />}
          </div>
        )}

        {!this.isComplete && !rule.cut_idx && !isPrevAppendixText && (
          <div
            className={rule.is_cutting ? styles.cutButtonActive : styles.cutButtonInactive}
            onClick={() => this.setRuleValues(rule, { is_cutting: !rule.is_cutting })}
          >
            <i className="fas fa-cut" />
          </div>
        )}
      </div>
    );
  }

  renderRuleCodes(rule) {
    if (rule.is_combined) return null;
    if (rule.aic === 'AT') return <button disabled={true}>Appendix Text</button>;

    const confidence = (rule.aic_confidence || '').toLowerCase();
    let numDots;

    switch (confidence) {
      case 'low':
        numDots = 1;
        break;
      case 'medium':
        numDots = 2;
        break;
      case 'high':
        numDots = 3;
        break;
      default:
        numDots = 0;
    }

    return (
      <div data-testid="aic-group-radio">
        <RadioButtons
          className={`${styles.fullWidthRadio}`}
          value={this.getGroupAic(rule)}
          onClick={val => this.setRuleValues(rule, { aic_group_input: val })}
          isDisabled={this.isComplete}
          options={codes.EXPANDED_AIC_GROUPS.map(code => {
            return {
              value: code.value,
              className: styles.fullWidthRadioButton,
              label: (
                <span className={`position-relative ${styles.fullWidthLabel}`}>
                  {code.shortLabel}
                  {(rule.aic_consolidated || '').toUpperCase() === code.value && (
                    <span className={`${styles.confidenceContainer} ${styles[confidence]}`}>
                      {Array.from(Array(numDots)).map((v, i) => (
                        <span key={i} className={styles.confidenceDot} />
                      ))}
                    </span>
                  )}
                </span>
              ),
            };
          })}
        />
      </div>
    );
  }
}

export default observer(
  decorate(ExpandedAssignAndCombine, {
    taskInputValue: computed,
    mergedFlatRules: computed,
    output: computed,
    errors: computed,
  })
);
