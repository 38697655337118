import React from 'react';
import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const SageLink = React.forwardRef((props, ref) => {
  /*
   * DEV NOTE:
   *
   * This is only useful while we are using legacy components in the new app.
   * after we retrofit the TaskTypes with material Ui, DELETE this file
   */
  return <Link ref={ref} component={NavLink} {...props} />;
});

export default SageLink;
