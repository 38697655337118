import React, { useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import SageMascot from '../../assets/images/sage-mascot.png';
import { useAuth } from '../Auth';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const DEFAULT_VALUES = { username: '', password: '' };

export const LoginPage = () => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState(DEFAULT_VALUES);
  const { errors, isLoading, login } = useAuth();

  const handleSubmit = async e => {
    e.preventDefault();
    await login(formValues);
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormValues(current => ({ ...current, [name]: value }));
  };

  return (
    <Container maxWidth="sm">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <img alt="Sage Mascot" src={SageMascot} />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            disabled={isLoading}
            autoFocus
            value={formValues.username}
            inputProps={{ onChange: handleInputChange, value: formValues.username }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            disabled={isLoading}
            id="password"
            value={formValues.password}
            inputProps={{ onChange: handleInputChange, value: formValues.password }}
            autoComplete="current-password"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isLoading}
            className={classes.submit}
          >
            Sign In
          </Button>
          {isLoading && <LinearProgress />}
          {errors &&
            errors.map((e, idx) => (
              <Alert key={`err-${idx}`} severity="error">
                {e}
              </Alert>
            ))}
        </form>
      </div>
    </Container>
  );
};

export default LoginPage;
