// TODO
// This component knows too much about groups
// and should be generalized to accept any kind of data.

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { colors } from '../../../tailwind';

const DropZoneWrapper = styled.div`
  ${tw`py-3 my-3 px-3`}
  background-color: ${
    props =>
      props.isActive
        ? props.isInGroup
          ? colors['yellow-lightest'] // Active Drop Zone inside of a group
          : colors['medium-gray'] // Active Drop Zone without an assigned group
        : props.isInGroup
        ? colors['green-lightest'] // Inactive Drop Zone inside of a group
        : colors['transparent'] // Inactive Drop Zone without an assigned group
  };
  border: 1px dashed
    ${props =>
      props.isActive
        ? props.isInGroup
          ? colors['yellow-dark'] // Same deal here...
          : colors['dark-gray']
        : props.isInGroup
        ? colors['green']
        : colors['light-gray']};
`;

const DropDefaults = {
  onDrop: e => {
    e.dataTransfer.getData('text');
  },
};

class DropZone extends Component {
  state = {
    isActive: false,
  };
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  };

  onDragOver = e => {
    if (e.preventDefault) e.preventDefault();
    this.setState({ isActive: true });
  };

  onDragLeave = e => {
    if (e.preventDefault) e.preventDefault();
    this.setState({ isActive: false });
  };

  handleDrop = e => {
    e.preventDefault();
    DropDefaults.onDrop(e);
    this.setState({ isActive: false });
    this.props.groupsStore.handleDropSelected(e, this.props.group);
  };

  render() {
    return (
      <DropZoneWrapper
        isActive={this.state.isActive}
        isInGroup={!!this.props.group}
        id={this.props.group ? this.props.group.id : ''}
        data-testid={`dropzone-${
          this.props.group && this.props.group.name
            ? this.props.group.name.replace(' ', '-')
            : 'nogroup'
        }`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.handleDrop}
      >
        {this.props.children}
      </DropZoneWrapper>
    );
  }
}

export default DropZone;
