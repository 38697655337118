import React, { createContext, useContext, useEffect, useState } from 'react';
import { LocalStorage } from 'ttl-localstorage';
import { ApolloConsumer, ApolloProvider, useMutation } from '@apollo/client';
import { makeApolloClient } from '../../clients/apollo';
import { LOGIN_MUTATION } from './queries';

export const AuthContext = createContext({
  errors: null,
  isLoading: false,
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
  token: null,
});

export const AuthProvider = ({ children }) => {
  const [errors, setErrors] = useState([]);
  const [token, setToken] = useState();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [authenticatedClient, setAuthenticatedClient] = useState();
  const [loginMutation, { loading: isLoading }] = useMutation(LOGIN_MUTATION);

  const setErrorObject = e => setErrors([JSON.stringify(e)]);

  const login = async variables => {
    try {
      const { data } = await loginMutation({ variables });
      const { token: apiToken, errors: apiErrors } = data.loginWorker;

      if (apiErrors && !!Object.keys(apiErrors).length) return setErrorObject(apiErrors);

      setToken(apiToken);
      setLoggedIn(!!apiToken);
      await LocalStorage.put('authToken', apiToken);
    } catch (e) {
      setErrorObject(e);
    }
  };

  const logout = async () => {
    await LocalStorage.removeKey('authToken');
    setToken(null);
    setLoggedIn(false);
  };

  const maybeRestoreLogin = async () => {
    const localToken = await LocalStorage.get('authToken');
    setToken(localToken);
    setLoggedIn(!!localToken);
  };

  useEffect(() => {
    maybeRestoreLogin();
  }, []);

  useEffect(() => {
    setAuthenticatedClient(makeApolloClient(token, logout));
  }, [token]);

  return (
    <AuthContext.Provider value={{ errors, isLoading, isLoggedIn, login, logout, token }}>
      <ApolloConsumer>
        {client => (
          <ApolloProvider client={authenticatedClient || client}>{children}</ApolloProvider>
        )}
      </ApolloConsumer>
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
