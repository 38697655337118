import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';

import MdIcon from '../../MdIcon';
import ConfirmDialog from '../../ConfirmDialog';
import { RESTART_ERRORED_STAGE_MUTATION, STAGES_QUERY } from '../queries';
import { PIPELINE_QUERY } from '../../Pipelines/queries';

const ErroredStageRestart = ({ stageId, stageName, pipelineId, ...restProps }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [restartErroredStage] = useMutation(RESTART_ERRORED_STAGE_MUTATION, {
    variables: { stageId },
    refetchQueries: [
      { query: PIPELINE_QUERY, variables: { id: pipelineId } },
      { query: STAGES_QUERY, variables: { pipelineId } },
    ],
  });

  return (
    <>
      <ConfirmDialog
        isOpen={dialogOpen}
        title="Are you sure you want to restart this stage?"
        description={`The stage "${stageName}" will be restarted`}
        onCancel={() => setDialogOpen(val => !val)}
        onConfirm={() => {
          restartErroredStage();
          setDialogOpen(val => !val);
        }}
      />
      <Alert
        component={Box}
        severity="error"
        action={
          <IconButton color="primary" size="small" onClick={() => setDialogOpen(val => !val)}>
            Retry <MdIcon name="restart_alt" />
          </IconButton>
        }
        {...restProps}
      >
        The <b>{stageName}</b> stage has failed. Would you like to retry?
      </Alert>
    </>
  );
};

export default ErroredStageRestart;
