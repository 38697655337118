import React, { Component } from 'react';
import Select from './Select';

class RangeSelect extends Component {
  render() {
    let {
      isDisabled = false,
      fromOptions,
      toOptions,
      defaultFromVal,
      defaultToVal,
      onChangeFrom,
      onChangeTo,
    } = this.props;
    return (
      <div className="grid-x grid-margin-x">
        <div className="cell medium-6">
          <span
            className="label bg-white float-left"
            style={{
              color: 'gray',
              border: '1px solid white',
              paddingTop: '1em',
              paddingBottom: '1em',
            }}
          >
            From:
          </span>
          <Select
            isDisabled={isDisabled}
            closeMenuOnSelect={true}
            isMulti={false}
            isSearchable={true}
            onChange={onChangeFrom}
            defaultValue={defaultFromVal}
            options={fromOptions}
          />
        </div>
        <div className="cell medium-6">
          <span
            className="label bg-white float-left"
            style={{
              color: 'gray',
              border: '1px solid white',
              paddingTop: '1em',
              paddingBottom: '1em',
            }}
          >
            To:
          </span>
          <Select
            isDisabled={isDisabled}
            closeMenuOnSelect={true}
            isMulti={false}
            isSearchable={true}
            onChange={onChangeTo}
            defaultValue={defaultToVal}
            options={toOptions}
          />
        </div>
      </div>
    );
  }
}
export default RangeSelect;
