import React, { useEffect, useState, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { FixedSizeList as ListWindow } from 'react-window';

import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';

import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import ProgressBar from '../../Progress/ProgressBar';
import Status from '../../Status';
import {
  DRAWER_GROUP_PROGRESS_QUERY,
  DRAWER_STAGE_PROGRESS_QUERY,
  DRAWER_TASKS_QUERY,
} from '../queries';

const DRAWER_WIDTH = 360;
const WINDOWED_ITEM_SIZE = 50;
const INTO_THE_ABYSS = -100000;

const useStyles = makeStyles(theme => ({
  drawerHeadSpacer: {
    minHeight: props => props.top,
  },
  drawerPaper: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    zIndex: 10,
    height: '100%',
    margin: 'unset',
    left: props => (props.isOpen ? props.left : INTO_THE_ABYSS),
    width: props => `${props.width}px`,
  },
  listHeader: {
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  listItem: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  listItemText: {
    margin: 'auto',
    width: '100%',
  },
  drawerHeading: {
    fontWeight: '600',
  },
}));

const DrawerHeader = forwardRef(
  ({ parent, drawerTitle, handleClose, topPosition, ...rest }, ref) => {
    const progress = {
      total: parent.progress.total,
      completed: parent.progress.completed,
    };

    const classes = useStyles({ top: topPosition });
    return (
      <Box p={1} m={1} w={1} ref={ref} {...rest}>
        <Box className={classes.drawerHeadSpacer} />
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.drawerHeading} variant="h6" gutterBottom={false}>
            {drawerTitle}
          </Typography>
          <IconButton size="small" onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <ProgressBar data-testid="drawer-progress" {...progress} />
      </Box>
    );
  }
);

const TaskRow = ({ task, ...rest }) => {
  const classes = useStyles();
  const taskDisplayName = task.name && task.name.length > 0 ? task.name : `Task #00${task.order}`;
  return (
    <ListItem component={Link} button p={1} to={`/tasks/${task.id}`} display="flex" {...rest}>
      <ListItemText className={classes.listItemText} disableTypography>
        <Typography noWrap>{taskDisplayName}</Typography>
      </ListItemText>
      <Status value={task.status} showIcon showText={false} />
    </ListItem>
  );
};

export function TasksDrawer({
  tasks,
  group,
  stage,
  isOpen,
  containerRef,
  handleClose,
  width = DRAWER_WIDTH,
  itemSize = WINDOWED_ITEM_SIZE,
}) {
  const parent = group || stage;

  const [bounds, setBounds] = useState({ height: 0, left: 0 });
  const headerRef = useRef();

  useEffect(() => {
    const { height: headerHeight } = headerRef.current.getBoundingClientRect();
    const { height: windowHeight } = document.body.getBoundingClientRect();
    const { left, top } = containerRef.current.getBoundingClientRect();
    const height = windowHeight - headerHeight - top;
    setBounds({ height, left, top });
  }, [containerRef, headerRef]);

  const classes = useStyles({ width, left: bounds.left, isOpen });
  const _WindowedTaskRow = ({ index, style: windowedStyle }) => {
    const task = tasks[index];
    return <TaskRow task={task} style={windowedStyle} className={classes.listItem} />;
  };

  return (
    <Box component={Paper} className={classes.drawerPaper}>
      <DrawerHeader
        ref={headerRef}
        parent={parent}
        drawerTitle={group ? group.name : stage.taskType.name}
        handleClose={handleClose}
        topPosition={bounds.top}
      />
      <List>
        <ListItem className={classes.listHeader}>
          <ListItemText
            align="left"
            primaryTypographyProps={{ className: classes.drawerHeading, variant: 'body1' }}
          >
            Name
          </ListItemText>
          <ListItemText
            align="right"
            primaryTypographyProps={{ className: classes.drawerHeading, variant: 'body1' }}
          >
            Status
          </ListItemText>
        </ListItem>
        <ListWindow
          height={bounds.height}
          width={width}
          itemSize={itemSize}
          itemCount={tasks.length}
        >
          {_WindowedTaskRow}
        </ListWindow>
      </List>
    </Box>
  );
}

TasksDrawer.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape({})),
  width: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  containerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object }),
  ]),
  group: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    progress: PropTypes.shape({
      total: PropTypes.number.isRequired,
      completed: PropTypes.number.isRequired,
      label: PropTypes.string,
    }),
  }),
  stage: PropTypes.shape({
    id: PropTypes.string,
    progress: PropTypes.shape({
      total: PropTypes.number.isRequired,
      completed: PropTypes.number.isRequired,
      label: PropTypes.string,
    }),
  }),
};

function TasksDrawerWithData({ groupId, stageId, ...rest }) {
  const variables = groupId ? { groupId } : { stageId };
  const shouldQueryGroups = !!groupId;

  const { loading, error, data } = useQuery(DRAWER_TASKS_QUERY, {
    variables,
  });

  const { loading: gLoading, error: gError, data: gData } = useQuery(DRAWER_GROUP_PROGRESS_QUERY, {
    variables: { id: groupId },
    skip: !shouldQueryGroups,
  });

  const { loading: sLoading, error: sError, data: sData } = useQuery(DRAWER_STAGE_PROGRESS_QUERY, {
    variables: { id: stageId },
    skip: shouldQueryGroups,
  });

  const anythingIsLoading = loading || gLoading || sLoading;
  const anyError = error || gError || sError;

  if (anythingIsLoading) return <LinearProgress />;
  if (anyError)
    return (
      <Alert severity="error">
        <pre>{JSON.stringify(anyError, null, 2)}</pre>
      </Alert>
    );

  return <TasksDrawer tasks={data.tasks} group={gData?.group} stage={sData?.stage} {...rest} />;
}

export default TasksDrawerWithData;

TasksDrawerWithData.propTypes = {
  groupId: PropTypes.string,
  stageId: PropTypes.string.isRequired,
};
