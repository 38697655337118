import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import image from '../../assets/images/undraw_Taken_if77.svg';

export default function NotFound() {
  return (
    <Box p={4} align="center">
      <img alt="Not Found" src={image} style={{ height: '33vh', margin: '3rem' }} />

      <Typography component="h1" variant="h4">
        Whoops! What are you looking for?
      </Typography>
    </Box>
  );
}
