import React, { createContext, useContext } from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createHttpLink } from '@apollo/client/link/http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

export const ApolloClientContext = createContext({ client: null });

export const ApolloClientProvider = ({ children, client }) => (
  <ApolloClientContext.Provider value={{ client }}>{children}</ApolloClientContext.Provider>
);

export const makeApolloClient = (token, onAuthError) => {
  const httpLink = createHttpLink({
    uri: `${window.location.protocol}//${window.location.host}/graphql/`,
  });

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  }));

  const resetToken = onError(({ networkError }) => {
    if (networkError?.statusCode === 401 && onAuthError) onAuthError();
  });

  const cache = new InMemoryCache({
    typePolicies: {
      Group: { merge: true },
      Pipeline: { merge: true },
      PipelineType: { merge: true },
      Stage: { merge: true },
      StageType: { merge: true },
      Task: { merge: true },
      TaskType: { merge: true },
    },
  });

  return new ApolloClient({
    link: resetToken.concat(authLink).concat(httpLink),
    cache: cache,
    connectToDevTools: true,
  });
};

export const useApolloClient = () => useContext(ApolloClientContext).client;
