import React from 'react';
import { default as ReactSelect } from 'react-select';

import styles from './Select.module.scss';

export default function Select({ className = '', ...restProps }) {
  return (
    <ReactSelect
      className={`${className} ${styles.select}`}
      styles={{
        placeholder: provided => ({
          ...provided,
          fontStyle: 'italic',
          color: '#cacaca',
        }),
        control: (provided, state) => ({
          ...provided,
          border: '0 !important',
          borderColor: 'transparent !important',
          boxShadow: state.isFocused
            ? '0 0 5px rgba(22, 61, 101, 0.66)'
            : 'inset 0 1px 2px rgba(22, 61, 101, 0)',
        }),
        container: provided => ({
          ...provided,
          border: '1px solid #cacaca',
          borderRadius: '4px',
          margin: '0 0 1rem',
        }),
      }}
      {...restProps}
    />
  );
}
