import React from 'react';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export default function Footer() {
  return (
    <Typography component={Box} pt={4} variant="body2" color="textSecondary" align="center">
      <Box>Copyright ©</Box>
      <Link color="inherit" to="https://ascentregtech.com">
        Ascent Technologies
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}
