import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import ActionAlert from '../../ActionAlert';
import { STAGE_QUERY, COMPLETE_STAGE_MUTATION } from '../queries';

const StageCompleteAlert = ({ stageId, confirmed = false, confirmProps = {}, ...restProps }) => {
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const [completeStage, { loading }] = useMutation(COMPLETE_STAGE_MUTATION, {
    variables: { stageId, confirmed },
    refetchQueries: [
      {
        query: STAGE_QUERY,
        variables: { id: stageId },
      },
    ],
  });

  const toggleOpen = () => setIsOpen(!isOpen);

  const handleCancel = () => {
    setError(null);
    setIsOpen(!isOpen);
  };

  const handleConfirm = async () => {
    setError(null);

    try {
      const {
        data: {
          completeStage: { success },
        },
      } = await completeStage();

      if (!success) setError('Encountered an error while completing stage');
      else setIsOpen(!isOpen);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <ActionAlert
      alertText="Manual complete action is required for this stage"
      actionText="COMPLETE THIS STAGE"
      actionHandler={toggleOpen}
      actionConfirm={{
        error,
        isOpen,
        loading,
        handleCancel,
        handleConfirm,
        title: 'Are you sure you want to complete this stage?',
        description: 'Warning, you might not be able to undo this action',
        ...confirmProps,
      }}
      {...restProps}
    />
  );
};

StageCompleteAlert.propTypes = {
  stageId: PropTypes.string.isRequired,
  confirmed: PropTypes.bool,
};

export const StageNoTasksAlert = ({ stageId, ...restProps }) => (
  <StageCompleteAlert
    stageId={stageId}
    alertText="No tasks were found for this stage"
    {...restProps}
  />
);

export const StageApprovalAlert = ({ stageId, ...restProps }) => (
  <StageCompleteAlert
    confirmed
    stageId={stageId}
    actionText="CONFIRM THIS STAGE"
    alertText="All tasks are complete"
    confirmProps={{
      title: 'Are you sure you want to approve this stage?',
      description: "Once you approve this stage, you won't be able to go back to edit any tasks",
    }}
    {...restProps}
  />
);

export default StageCompleteAlert;
