import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import Box from '@material-ui/core/Box';

import NotFound from '../../NotFound';
import TaskPage from '../../Tasks/TaskPage';
import StagePage from '../../Stages/StagePage';
import PipelinePage from '../../Pipelines/PipelinePage';
import PipelineStartPageWithData from '../../Pipelines/PipelineStartPage';
import {
  PipelinesInProgressPage,
  PipelinesRecentlyCompletedPage,
} from '../../Pipelines/PipelinesPage';

const Main = () => (
  <Box
    component="main"
    py={4}
    style={{
      margin: 0,
      padding: 0,
      width: '100%',
      height: '100%',
      overflow: 'auto',
    }}
  >
    <Switch>
      <Route exact path="/">
        <Redirect to={{ pathname: '/pipelines/in-progress' }} />
      </Route>
      <Route path="/pipelines/in-progress" exact component={PipelinesInProgressPage} />
      <Route
        path="/pipelines/recently-completed"
        exact
        component={PipelinesRecentlyCompletedPage}
      />
      <Route path="/pipelines/:pipelineId" component={PipelinePage} />
      <Route path="/stages/:stageId" component={StagePage} />
      <Route path="/tasks/:taskId" component={TaskPage} />
      <Route path="/start/:status?" component={PipelineStartPageWithData} />
      <Route component={NotFound} />
    </Switch>
  </Box>
);

export default Main;
